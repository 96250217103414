var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", [_vm._v("Упс! Что-то пошло не так")]),
    _vm._v(" "),
    _vm.uploading
      ? _c("p", [_vm._v("Отправляем данные программистам...")])
      : _c("p", [
          _vm._v(
            "Информации об ошибке уже отправлена в службу поддержки. Опишите события, которые предшестовали ошибке. Это\n    позволит нам быстрее найти и исправить ошибку."
          ),
        ]),
    _vm._v(" "),
    _c("p", [
      _c("textarea", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.message,
            expression: "message",
          },
        ],
        staticStyle: { width: "100%", height: "300px" },
        domProps: { value: _vm.message },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.message = $event.target.value
          },
        },
      }),
    ]),
    _vm._v(" "),
    _c("p", [
      _c(
        "button",
        {
          on: {
            click: function ($event) {
              return _vm.uploadUserMessage()
            },
          },
        },
        [_vm._v("Отправить")]
      ),
      _vm._v(" "),
      _vm.userMessageState === "uploading"
        ? _c("span", [_vm._v("Отправляем данные...")])
        : _vm.userMessageState === "complete"
        ? _c("span", [_vm._v("Сообщение отправлено. Спасибо за вашу помощь")])
        : _vm._e(),
    ]),
    _vm._v(" "),
    _c(
      "p",
      [
        _c("router-link", { attrs: { to: { name: "root" } } }, [
          _vm._v("Вернуться на главную страницу"),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }