<template>
  <div>
    <h1 class="title">Оформление заказа</h1>
    <div class="panels">
      <div>
        <div v-if="data['Статус'] === 'Создан'" class="form-created">
          <h5>Заполните поля заказа</h5>
          <div>Номер заказа {{ data['Номер'] }}</div>
          <div v-for="input in data['Реквизиты']">
            {{ input['Имя'] }}<span v-if="input['Обязательный']" style="color: red">*</span>
            <input v-model="input['Значение']" type="text">
            <span class="error">{{ errors[input['ГУИД']] }}</span>
          </div>
          <div>
            <button @click="saveDetails()" class="btn">Далее</button>
          </div>
        </div>
        <div v-else-if="data['Статус'] === 'Заполнен'" class="form-completed">
          <h4>Заказ оформлен и ожидает оплаты</h4>
          <div>
            <div class="details">
              <div>Номер заказа</div>
              <div>{{ data['Номер'] }}</div>
              <template v-for="input in data['Реквизиты']">
                <div>{{ input['Имя'] }}:</div>
                <div>{{ input['Значение'] }}</div>
              </template>
            </div>
          </div>
          <div>
            <button @click="proceedToCheckout()" class="btn">Перейти к оплате</button>
          </div>
        </div>
        <div v-else-if="data['Статус'] === 'Оплачен'" class="form-paid">
          <h4>Заказ оплачен</h4>
          <div>
            <div class="details">
              <div>Номер заказа</div>
              <div>{{ data['Номер'] }}</div>
              <template v-for="input in data['Реквизиты']">
                <div>{{ input['Имя'] }}:</div>
                <div>{{ input['Значение'] }}</div>
              </template>
            </div>
          </div>
        </div>
        <a v-if="data['Чек']" :href="data['Чек']['Ссылка']" class="link check" target="_blank">Кассовый чек</a>
      </div>
      <div>
        <h2>{{ data["Услуга"]['Наименование'] }}</h2>
        <div class="image_specification">
          <div><img :src="data['Услуга']['Картинка'] | imagePath"></div>
          <div class="specification">
            <template v-for="item of data['Услуга']['Характеристики']">
              <div>{{ item[0] }}</div>
              <div>{{ item[1] }}</div>
            </template>
          </div>
        </div>
        <div class="options">
          <template v-for="item of data['Услуга']['Опции']">
            <div>{{ item[0] }}</div>
            <div>{{ item[1] }}</div>
          </template>
          <template v-if="isCalendarMode">
            <div>Время записи (Смена)</div>
            <div>{{ data["Смена"]["Дата"] }} {{ data["Смена"]["Наименование"] }}</div>
          </template>
          <template v-if="isAmountMode">
            <div>Количество</div>
            <div>{{ data["Услуга"]['Количество'] }}</div>
          </template>
        </div>
        <div class="amount">{{ cost | currency }}</div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      data: {
        "Услуга": {}
      },
      errors: {}
    }
  },
  watch: {
    $route: 'init'
  },
  computed: {
    isAmountMode() {
      const mode = this.data['Режим'];
      return Array.isArray(mode) && mode.includes("Количество")
    },
    isCalendarMode() {
      const mode = this.data['Режим'];
      return Array.isArray(mode) && mode.includes("Календарь")
    },
    storeId() {
      return this.$route.params.storeId;
    },
    orderId() {
      return this.$route.params.orderId;
    },
    cost() {
      let cost = this.data['Услуга']['Цена']

      if (this.isAmountMode) cost *= this.data["Услуга"]['Количество']

      return cost;
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.getOrderStatus()
    },
    async getOrderStatus() {
      this.data = await this.$remoteCalls.V1.Order.GetStatus({order_id: this.orderId})
    },
    async saveDetails() {
      this.errors = {}

      let data = await this.$remoteCalls.V1.Order.SaveDetails({
        order_id: this.orderId,
        details: Object.fromEntries(this.data['Реквизиты'].map(it => [it['ГУИД'], it['Значение']]))
      })

      if (data['Ошибки']) {
        this.errors = data['Ошибки']
      } else if (data['Заказ']) {
        this.data = Object.assign(this.data, data['Заказ'])
      }
    },
    async proceedToCheckout() {
      const url = await this.$remoteCalls.V1.PaymentGateway.Link({
        order_id: this.orderId
      })

      window.location.href = url
    }
  }
}
</script>

<style scoped lang="scss">
.panels {
  display: flex;
  justify-content: center;
  margin: 0 -16px;

  > * {
    padding: 32px;
    margin: 16px;
    border: 1px solid #DADADA;

    flex-grow: 1;
  }
}

.form-created {
  display: flex;
  flex-direction: column;

  > * {
    font-family: 'Roboto', sans-serif;
    margin: 16px 0;
  }

  input {
    box-sizing: border-box;
    width: 100%;
    border: 1px solid #DADADA;
    padding: 15px 22px;
    margin: 8px 0;
  }
}

.form-completed {
  display: flex;
  flex-direction: column;

  > * {
    margin: 16px 0;
  }
}

.error {
  color: red;
}

h5 {
  font-size: 1rem;
  font-weight: bold;
}

.details {
  display: grid;
  grid-template-columns: auto auto;
  margin: 0 -8px;

  > div {
    margin: 8px;
  }
}

.check {
  margin: 16px 0;
  display: block;
}

.image_specification {
  display: flex;
  margin: 0 -12px;

  > * {
    margin: 24px 12px;
  }
}

.specification {
  font-family: 'Roboto', sans-serif;

  flex-grow: 1;

  display: grid;
  grid-template-columns: auto auto;
  align-content: start;

  > * {
    margin-bottom: 16px;
  }
}

.options {
  font-family: 'Roboto', sans-serif;
  color: #282828;

  display: grid;
  grid-template-columns: auto auto;

  > * {
    margin-bottom: 16px;
  }
}

.amount {
  font-size: 1.5rem;
  font-weight: bold;
  color: #8D0AF4;

  margin-top: 85px;
}

</style>