<template>
  <div>
    <h1 class="office__section-header">Избранное</h1>
    <div class="favorites">
      <div v-for="it in favorites" :class="{unpublished: !it['Опубликовано']}">
        <img :src="it['Картинка'] | imagePath">
        <div class="description">
          <div class="title">{{ it["Наименование"] }}</div>
          <div class="operations">
            <button @click="toggleFavorite(it)" :disabled="it.updating" class="btn favorite">
              <i :class="[it['Включен'] ? 'fa-solid' : 'fa-regular' ]" class="fa-xl fa-heart"></i>
            </button>
            <router-link v-if="it['Опубликовано']"
                         :to="{ name: 'service', params: { storeId: it['Магазин'], serviceId: it['Услуга'] } }"
                         class="btn">
              Перейти
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "favorites",
  data() {
    return {
      favorites: []
    }
  },
  methods: {
    async loadFavorites() {
      let favorites = await this.$remoteCalls.V1.Favorites.List();
      this.favorites = favorites.map((it) => {
        it["Включен"] = true;
        it.updating = false;
        return it;
      });
    },
    async toggleFavorite(it) {
      try {
        this.updating = true;
        if (it["Включен"]) {
          await this.$remoteCalls.V1.Favorites.Delete({service_id: it["Услуга"]})
          it["Включен"] = false;
        } else {
          await this.$remoteCalls.V1.Favorites.Add({service_id: it["Услуга"]})
          it["Включен"] = true;
        }
      } catch (e) {
        this.$handleError(e);
      } finally {
        this.updating = false;
      }
    }
  },
  created() {
    this.loadFavorites();
  }
}
</script>

<style scoped lang="scss">

.favorites {
  display: flex;
  flex-direction: column;
  margin: 48px 0;

  > * {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #DADADA;
    margin: 12px 0;

    img {
      display: block;
      margin: 22px;
      width: 156px;
      height: 156px;
    }
  }

  .description {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex-grow: 1;
    margin: 22px;

    .title {
      font-weight: bold;
      font-size: 1.5rem;
    }

    .operations {
      display: flex;
      justify-content: flex-end;
      gap: 12px;
      margin-top: 12px;
    }
  }

  .btn.favorite {
    padding: 8px 12px;
    color: red;
    background-color: rgb(242, 245, 249);

    &:hover {
      opacity: revert;
      background-color: rgb(226, 231, 236);
    }
  }
}

.unpublished {
  background: #eaeaea;
}
</style>