<template>
  <div>
    <div class="shop-header">{{ data['НаименованиеРаздела'] || "Раздел" }}</div>
    <div class="shop-service-content">
      <breadcrumbs-component :chain="breadcrumbs"></breadcrumbs-component>
      <div class="service-container">
        <h1 ref="header" class="header">{{ data['Наименование'] || "Услуга" }}</h1>
        <div>
          <div class="service__image">
            <img :src="imageFirst | imagePath" alt="">
            <div class="service__specification">
              <template v-for="item in data['Характеристики']">
                <div class="service__specification-name"><span>{{ item['Наименование'] }}</span></div>
                <div>{{ item['Значения'].join(', ') }}</div>
              </template>
            </div>
          </div>
        </div>
        <div>
          <div class="service__parameters">
            <form-select v-for="itemSelect in data['Опции']" :value="selectedValues[itemSelect['ГУИД']]"
                         @input="selectOption(itemSelect['ГУИД'], $event)"
                         :options="itemSelect['Значения'] | adaptList(itemSelect['ГУИД'], allowedOptions, selectedValues[itemSelect['ГУИД']])"
                         :key="itemSelect['ГУИД']">
              {{ itemSelect["Наименование"] }}
            </form-select>
            <div v-if="isCalendarMode" class="showtimes">
              <div>Время сеанса</div>
              <form-calendar v-model="calendar.selectedDate" :work-days="calendar.workDays"
                             @calendar-changed="calendarChanged($event)"></form-calendar>
              <form-select v-model="calendar.selectedHour" :options="calendar.openingHours | adaptOpeningHours">Сеансы
              </form-select>
            </div>
            <div v-if="isAmountMode" class="amount">
              <label for="amount">Количество</label>
              <input v-model.number="amount" type="number" min="1" step="1" id="amount">
            </div>
            <div>
              <div v-if="clearanceCompleted" class="service__amount-container">
                <div class="service__amount-title">Итого</div>
                <div class="service__amount">{{ currentPrice | currency }}</div>
              </div>
              <div v-else>
                Для получения цены выберите опции
              </div>
            </div>
            <div class="service__amount_favorite_buttons">
              <button :disabled="!clearanceCompleted" @click="createOrder()" class="btn">Оформить заказ</button>
              <button @click="toggleFavorite()" :disabled="updatingFavorite" class="btn">
                <i :class="[favorite ? 'fa-solid' : 'fa-regular' ]" class="fa-xl fa-heart"></i>
              </button>
            </div>
          </div>
        </div>
        <div class="service__description">
          <h4 class="service__description-title">Описание:</h4>
          <div v-html="$options.filters.markdown(data['Описание'] || '')"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/components/common/form-select";
import FormCalendar from "@/components/common/form-calendar";

export default {
  data() {
    return {
      data: {
        "Варианты": [],
        "Цены": []
      },
      currentTab: 'tab-description',
      selectedValues: {},
      price: null,
      amount: 1,
      favorite: false,
      updatingFavorite: false,
      // Режим календаря
      calendar: {
        openDate: null,
        selectedDate: null,
        openingHours: [],
        selectedHour: null,
        workDays: null
      }
    };
  },
  computed: {
    isAmountMode() {
      const mode = this.data['Режим'];
      return Array.isArray(mode) && mode.includes("Количество")
    },
    isCalendarMode() {
      const mode = this.data['Режим'];
      return Array.isArray(mode) && mode.includes("Календарь")
    },
    breadcrumbs() {
      const path = (this.data['Путь'] || [])
      return path.map(it => {
        return {name: it['Наименование'], to: {name: 'services', params: {serviceId: it['ГУИД']}}}
      });
    },
    serviceId() {
      return this.$route.params.serviceId;
    },
    storeId() {
      return this.$route.params.storeId;
    },
    currentPrice() {
      if (this.allowedPrices.length === 0) return 0;
      let price = this.allowedPrices[0]["Цена"];
      if (this.isAmountMode) price = price * this.amount;
      return price;
    },
    clearanceCompleted() {
      let values = Object.entries(this.selectedValues);
      let completed = !values.some(item => item[1] == null)

      if (this.isCalendarMode)
        completed = completed && this.calendar.selectedHour

      if (this.isAmountMode) completed = completed && this.amount >= 1

      return completed;
    },
    allowedPrices() {
      return Object.entries(this.selectedValues).reduce((prices, selectedItem) => {
        if (selectedItem[1] == null) return prices;
        return prices.filter(price => price["Опции"][selectedItem[0]] === selectedItem[1])
      }, this.data['Цены'])
    },
    allowedOptions() {
      let prices = this.data["Цены"]
      let options = this.data["Опции"]

      let allowedOptions = options.map((option) => {
        let allowedPrices = Object.entries(this.selectedValues).reduce((prices, selectedItem) => {
          if (selectedItem[1] == null || option["ГУИД"] === selectedItem[0]) return prices;
          return prices.filter(price => price["Опции"][selectedItem[0]] === selectedItem[1])
        }, prices)

        return [option["ГУИД"], new Set(allowedPrices.map((item) => item["Опции"][option["ГУИД"]]))]
      })

      return Object.fromEntries(allowedOptions)
    },
    imageFirst() {
      let images = this.data['Картинки'] || []
      if (images.length > 0)
        return images[0]
      return null;
    }
  },
  watch: {
    $route: 'init',
    'calendar.selectedDate': async function (date) {
      this.calendar.selectedHour = null;
      this.calendar.openingHours = [];

      this.calendar.openingHours = await this.$remoteCalls.V1.Calendar.OpeningHours({
        service_id: this.serviceId,
        date: date
      })
    }
  },
  created() {
    this.GetService();
    this.GetFavoriteState();
  },
  methods: {
    async calendarChanged(date) {
      this.calendar.workDays = null;
      this.calendar.workDays = new Set(await this.$remoteCalls.V1.Calendar.GetCalendar({
        service_id: this.serviceId,
        year: date.year,
        month: date.month
      }));
    },
    selectOption(id, value) {
      if (!value) this.selectedValues[id] = null;

      if (!this.allowedOptions[id].has(value)) return;

      if (this.selectedValues[id] === value)
        this.selectedValues[id] = null;
      else
        this.selectedValues[id] = value;
    },
    async GetService() {
      try {
        this.data = await this.$remoteCalls.V1.Service.GetService({
          store_id: this.storeId,
          service_id: this.serviceId
        })

        this.selectedValues = this.data["Опции"].reduce((obj, item) => {
          obj[item['ГУИД']] = null;
          return obj;
        }, {})

        this.$nextTick( () => this.$refs.header.scrollIntoView(true))
      } catch (e) {
        this.$handleError(e)
      }
    },
    async GetFavoriteState() {
      try {
        this.updatingFavorite = true;
        this.favorite = await this.$remoteCalls.V1.Favorites.GetState({service_id: this.serviceId});
      }
      catch (e) {
        if (e instanceof this.$remoteErrors.UserNotAuthenticated) return;

        this.$handleError(e)
      }
      finally {
        this.updatingFavorite = false;
      }
    },
    async createOrder() {
      try {
        let servicePriceId = this.allowedPrices[0]['ГУИД']

        let params = {
          "Услуга": this.serviceId,
          "Номенклатура": servicePriceId
        }

        if (this.isCalendarMode)
          params["Смена"] = this.calendar.selectedHour;

        if (this.isAmountMode)
          params["Количество"] = this.amount;

        let orderId = await this.$remoteCalls.V1.Order.Create(params)

        await this.$router.push({name: 'order', params: {orderId}})
      } catch (e) {
        if (e instanceof this.$remoteErrors.UserNotAuthenticated) {
          await this.$router.push({name: 'login', query: {redirect: this.$route.fullPath}})
          return
        }
        this.$handleError(e)
      }
    },
    async toggleFavorite() {
      try {
        this.updatingFavorite = true;
        if (this.favorite) {
          await this.$remoteCalls.V1.Favorites.Delete({service_id: this.serviceId})
          this.favorite= false;
        } else {
          await this.$remoteCalls.V1.Favorites.Add({service_id: this.serviceId})
          this.favorite = true;
        }
      } catch (e) {
        if (e instanceof this.$remoteErrors.UserNotAuthenticated) {
          await this.$router.push({name: 'login', query: {redirect: this.$route.fullPath}})
          return
        }
        this.$handleError(e);
      } finally {
        this.updatingFavorite = false;
      }
    }
  },
  filters: {
    adaptOpeningHours(list) {
      return list.map((it) => {
        return {
          name: it['Наименование'],
          value: it['ГУИД'],
          disabled: false
        }
      })
    },
    adaptList(list, optionId, allowedOptions, selectedValue) {
      return list.map((it) => {
        return {
          name: it['Наименование'],
          value: it['ГУИД'],
          disabled: !allowedOptions[optionId].has(it['ГУИД']),
          selected: selectedValue === it['ГУИД']
        };
      })
    }
  },
  components: {
    'form-select': FormSelect,
    'form-calendar': FormCalendar
  }
}
</script>

<style scoped lang="scss">

.service-container {
  display: grid;
  grid-template-columns: 6fr 4fr;
  column-gap: 32px;

  > * {
    box-sizing: border-box;
  }
}

.header {
  grid-column: 1 / -1;
}

.service__image {
  padding: 16px;

  border: 1px solid #dadada;

  img {
    display: block;
    width: 100%;
  }
}

.service__specification {
  display: grid;
  grid-template-columns: auto auto;
  margin-top: 24px;
  row-gap: 21px;
  column-gap: 12px;

  font-family: "Roboto", sans-serif;
}

.service__specification-name {
  position: relative;

}

.service__specification-name::before {
  border-bottom: 1px solid rgb(218, 218, 218);
  bottom: .2em;
  content: "";
  display: block;
  left: 0;
  position: absolute;
  width: 100%;
}

.service__specification-name > span {
  background: #fff;
  display: inline;
  padding-right: 3px;
  position: relative;
}

.service__parameters {
  min-width: 350px;
  padding: 26px 16px 26px;

  display: flex;
  flex-direction: column;
  gap: 16px;

  border: 1px solid #dadada;
}

.service__description-title {
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
  font-size: 1rem;
}

.service__amount-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin: 0 -8px;

  > div {
    margin: 8px;
  }
}

.service__amount-title {
  font-weight: bold;
  font-size: 1rem;
}

.service__amount {
  font-size: 2rem;
  font-weight: bold;
}

.service__amount_button {
  text-align: right;
}

.service__amount_favorite_buttons {
  display: flex;
  gap: 16px;

  button:first-child {
    flex-grow: 1;
  }

  button:last-child {
    padding: 8px 12px;
    color: red;
    background-color: rgb(242, 245, 249);

    &:hover {
      opacity: revert;
      background-color: rgb(226, 231, 236);
    }
  }
}

.service__description {
  grid-column: 1 / -1;

  font-family: 'Roboto', sans-serif;
  text-align: justify;

  margin-bottom: 48px;
}

.amount {
  display: flex;
  justify-content: space-between;
  align-items: center;

  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.amount input {
  box-sizing: border-box;
  border: #DADADA solid 1px;
  border-radius: 2px;
  padding: 14px 20px;
  text-align: center;
  max-width: 120px;
}

.showtimes {
  & > div {
    margin: 8px 0;
  }
}

@media screen and (max-width: $tablet-width) {
  .service-container {
    grid-template-columns: auto;
  }

  .service__image {
    border-bottom: none;
  }

  .service__parameters {
    border-top: none;
  }
}
</style>