import MarkdownIt from 'markdown-it'
import ImageNotFound from '@/assets/images/image-not-found.svg'

export default function (Vue) {
    let currencyFormatter = new Intl.NumberFormat('ru-RU', {style: 'currency', currency: 'RUB'})
    Vue.filter('currency', function (value) {
        return currencyFormatter.format(value)
    })

    Vue.filter('dateTime', function (value) {
        if (!value) return;

        if (typeof value === 'string')
            value = new Date(value)

        return value.toLocaleString();
    });

    let md = new MarkdownIt()

    // Remember old renderer, if overridden, or proxy to default renderer
    let defaultRender = md.renderer.rules.link_open || function (tokens, idx, options, env, self) {
        return self.renderToken(tokens, idx, options);
    };

    md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        // If you are sure other plugins can't add `target` - drop check below
        var aIndex = tokens[idx].attrIndex('target');

        if (aIndex < 0) {
            tokens[idx].attrPush(['target', '_blank']); // add new attribute
        } else {
            tokens[idx].attrs[aIndex][1] = '_blank';    // replace value of existing attr
        }

        // pass token to default renderer.
        return defaultRender(tokens, idx, options, env, self);
    };

    Vue.filter('markdown', function (value) {
        return md.render(value)
    })

    Vue.filter('imagePath', function (id) {
        return id ? `/api/file/${id}` : ImageNotFound
    });
}