var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "shop-top-content" }, [
        _c("div", { staticClass: "shop-header" }, [
          _vm._v(_vm._s(_vm.data["НаименованиеРаздела"] || "Раздел")),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "shop-groups-wrapper" }, [
          _c(
            "div",
            { staticClass: "shop-groups" },
            _vm._l(_vm.data["Группы"], function (item) {
              return _c(
                "div",
                { key: item["ГУИД"], staticClass: "shop-group" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "services",
                          params: {
                            storeId: _vm.storeId,
                            serviceId: item["ГУИД"],
                          },
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: { src: _vm._f("imagePath")(item["Миниатюра"]) },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "shop-group__text" }, [
                        _vm._v(_vm._s(item["Наименование"])),
                      ]),
                    ]
                  ),
                ],
                1
              )
            }),
            0
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("breadcrumbs-component", { attrs: { chain: _vm.breadcrumbs } }),
      _vm._v(" "),
      _c("div", { staticClass: "shop-services-header" }, [
        _c("h1", { staticClass: "header" }, [_vm._v("Услуги")]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "shop-filters" },
          _vm._l(_vm.data["Фильтры"], function (filter) {
            return _c(
              "div",
              [
                _c(
                  "form-select",
                  {
                    attrs: { options: _vm._f("adaptList")(filter["Значения"]) },
                    on: { input: _vm.fetchData },
                    model: {
                      value: _vm.selectedFilters[filter["ГУИД"]],
                      callback: function ($$v) {
                        _vm.$set(_vm.selectedFilters, filter["ГУИД"], $$v)
                      },
                      expression: "selectedFilters[filter['ГУИД']]",
                    },
                  },
                  [_vm._v(_vm._s(filter["Наименование"]) + "\n        ")]
                ),
              ],
              1
            )
          }),
          0
        ),
        _vm._v(" "),
        _vm.data["Элементы"].length > 0 || _vm.data["Фильтры"].length > 0
          ? _c("div", { staticClass: "shop-clear-filter-link" }, [
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearFilters()
                    },
                  },
                },
                [_vm._v("Очистить фильтры")]
              ),
            ])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _vm.data["Элементы"].length > 0
        ? _c(
            "div",
            { staticClass: "tiles" },
            _vm._l(_vm.data["Элементы"], function (item) {
              return _c(
                "div",
                { key: item["ГУИД"], staticClass: "tile" },
                [
                  _c(
                    "router-link",
                    {
                      attrs: {
                        to: {
                          name: "service",
                          params: {
                            storeId: _vm.storeId,
                            serviceId: item["ГУИД"],
                          },
                        },
                      },
                    },
                    [
                      _c("img", {
                        staticClass: "tile__image",
                        attrs: {
                          src: _vm._f("imagePath")(item["Миниатюра"]),
                          alt: "",
                        },
                      }),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "tile__content" },
                    [
                      _c(
                        "router-link",
                        {
                          staticClass: "tile__header",
                          attrs: {
                            to: {
                              name: "service",
                              params: {
                                storeId: _vm.storeId,
                                serviceId: item["ГУИД"],
                              },
                            },
                          },
                        },
                        [_vm._v(_vm._s(item["Наименование"]) + "\n        ")]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "tile__amount" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm._f("amount")(item["Стоимость"])) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "tile__description" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(item["КраткоеОписание"]) +
                            "\n        "
                        ),
                      ]),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "tile__actions" },
                        [
                          _c(
                            "router-link",
                            {
                              staticClass: "btn",
                              attrs: {
                                to: {
                                  name: "service",
                                  params: {
                                    storeId: _vm.storeId,
                                    serviceId: item["ГУИД"],
                                  },
                                },
                              },
                            },
                            [_vm._v("\n            подробнее\n          ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            0
          )
        : _c("div", [_c("h4", [_vm._v("Нет данных")])]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }