<template>
  <div class="panel">
    <h2>Ошибка #{{ errorId }}</h2>
    <div class="error">
      <div class="title">Дата</div>
      <div class="value">{{ error.created_at | dateTime }}</div>
      <div class="title">Имя</div>
      <div class="value">{{ error.name }}</div>
      <div class="title">Описание</div>
      <div class="value">{{ error.message ? error.message : ' --- ' }}</div>
      <div class="title">Сообщение от пользователя</div>
      <div class="value">{{ error.user_message ? error.user_message : ' --- ' }}</div>
      <div class="title">Страница</div>
      <div class="value">{{ error.location }}</div>
      <div class="title">Файл</div>
      <div class="value">{{ error.file_name }} {{ error.line_number }}:{{ error.column_number }}</div>
      <div class="title">Стек вызовов функций</div>
      <div>
        <pre>{{ error.stack }}</pre>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "error",
  data() {
    return {
      error: {}
    }
  },
  async created() {
    this.error = await this.$remoteCalls.V1.Error.GetError({error_id: this.errorId});
  },
  computed: {
    errorId() {
      return this.$route.params.errorId;
    }
  }
}
</script>

<style scoped lang="scss">

pre {
  margin: 0;
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.error {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.value {
  font-family: 'Roboto', sans-serif;
}

.title {
  margin-top: 16px;
  font-weight: bold;
}

</style>