var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", {
      staticClass: "custom-select-overlay",
      class: { open: _vm.isOpen },
      on: {
        click: function ($event) {
          _vm.isOpen = false
        },
      },
    }),
    _vm._v(" "),
    _c(
      "div",
      {
        staticClass: "custom-select-wrapper",
        on: {
          click: function ($event) {
            _vm.isOpen = !_vm.isOpen
          },
        },
      },
      [
        _c(
          "div",
          { staticClass: "custom-select", class: { open: _vm.isOpen } },
          [
            _c("div", { staticClass: "custom-select__trigger item" }, [
              _vm.selectedItem
                ? _c(
                    "div",
                    {
                      staticClass: "custom-option",
                      class: { selected: !!_vm.selectedItem },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.selectedItem.name) +
                          "\n        "
                      ),
                    ]
                  )
                : _c(
                    "div",
                    { staticClass: "custom-option" },
                    [_vm._t("default")],
                    2
                  ),
              _vm._v(" "),
              _c("div", { staticClass: "commands" }, [
                _c("i", { staticClass: "fas fa-chevron-down" }),
                _vm._v(" "),
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: !!_vm.selectedItem,
                      expression: "!!selectedItem",
                    },
                  ],
                  staticClass: "fas fa-times",
                  on: {
                    click: function ($event) {
                      $event.stopPropagation()
                      return _vm.clearItem()
                    },
                  },
                }),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "custom-options" },
              _vm._l(_vm.options, function (item) {
                return _c(
                  "span",
                  {
                    staticClass: "custom-option item",
                    class: { disabled: item.disabled, selected: item.selected },
                    on: {
                      click: function ($event) {
                        return _vm.$emit("input", item.value)
                      },
                    },
                  },
                  [_vm._v("\n          " + _vm._s(item.name) + "\n        ")]
                )
              }),
              0
            ),
          ]
        ),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }