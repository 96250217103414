<template>
  <div>
    <h1 class="office__section-header">Личные данные</h1>
    <div class="data">
      <div class="grid">
        <div>Фамилия</div>
        <div><input v-model="userInfo['Фамилия']" type="text"></div>
        <div>Имя</div>
        <div><input v-model="userInfo['Имя']" type="text"></div>
        <div>Отчество</div>
        <div><input v-model="userInfo['Отчество']" type="text"></div>
        <div>Email</div>
        <div><input v-model="userInfo['Email']" type="text"></div>
        <div>Телефон</div>
        <div><input v-model="userInfo['Телефон']" type="text"></div>
        <div></div>
        <div class="last">
          <button @click="saveUserInfo" :disabled="saving" class="btn"><i v-show="saving" class="fas fa-cog fa-spin"></i> Сохранить изменения</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinksComponent from '@/components/my-office/links'
import { pick } from 'lodash'

export default {
  data() {
    return {
      userInfo: {},
      saving: false
    }
  },
  created() {
    this.init();
  },
  methods: {
    async init() {
      this.userInfo = await this.$remoteCalls.V1.User.GetInfo();
    },
    async saveUserInfo() {
      try {
        const data = pick(this.userInfo, ['Фамилия', 'Имя', 'Отчество', 'Email', 'Телефон'])

        this.saving = true;
        await this.$remoteCalls.V1.User.UpdateInfo(data);
        this.saving = false
      }
      catch (e) {
        this.$handleError(e);
      }
    }
  },
  components: {
    'links-component': LinksComponent
  }
}
</script>

<style scoped lang="scss">

.data {
  margin: 80px 0;
  max-width: 700px;

  font-family: 'Roboto', sans-serif;
}

.grid {
  display: grid;
  grid-template-columns: repeat(2, auto);
  align-items: center;

  > * {
    margin: 8px;
  }
}

.last {
  margin-top: 60px;
}

input {
  width: 100%;
  border: 1px solid #DADADA;
  padding: 15px 22px;
  box-sizing: border-box;
}
</style>