var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "modal" }, [
        _c("h1", [_vm._v("Техническое обслуживание")]),
        _vm._v(
          "\n    В настоящее время мы проводим плановое техническое обслуживание. Мы постараемся завершить работы\n    как можно скорее. Приносим извинения за доставленные неудобства\n  "
        ),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }