var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title" }, [_vm._v("Оформление заказа")]),
    _vm._v(" "),
    _c("div", { staticClass: "panels" }, [
      _c("div", [
        _vm.data["Статус"] === "Создан"
          ? _c(
              "div",
              { staticClass: "form-created" },
              [
                _c("h5", [_vm._v("Заполните поля заказа")]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("Номер заказа " + _vm._s(_vm.data["Номер"])),
                ]),
                _vm._v(" "),
                _vm._l(_vm.data["Реквизиты"], function (input) {
                  return _c("div", [
                    _vm._v("\n          " + _vm._s(input["Имя"])),
                    input["Обязательный"]
                      ? _c("span", { staticStyle: { color: "red" } }, [
                          _vm._v("*"),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: input["Значение"],
                          expression: "input['Значение']",
                        },
                      ],
                      attrs: { type: "text" },
                      domProps: { value: input["Значение"] },
                      on: {
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(input, "Значение", $event.target.value)
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c("span", { staticClass: "error" }, [
                      _vm._v(_vm._s(_vm.errors[input["ГУИД"]])),
                    ]),
                  ])
                }),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      on: {
                        click: function ($event) {
                          return _vm.saveDetails()
                        },
                      },
                    },
                    [_vm._v("Далее")]
                  ),
                ]),
              ],
              2
            )
          : _vm.data["Статус"] === "Заполнен"
          ? _c("div", { staticClass: "form-completed" }, [
              _c("h4", [_vm._v("Заказ оформлен и ожидает оплаты")]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "details" },
                  [
                    _c("div", [_vm._v("Номер заказа")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.data["Номер"]))]),
                    _vm._v(" "),
                    _vm._l(_vm.data["Реквизиты"], function (input) {
                      return [
                        _c("div", [_vm._v(_vm._s(input["Имя"]) + ":")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(input["Значение"]))]),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "button",
                  {
                    staticClass: "btn",
                    on: {
                      click: function ($event) {
                        return _vm.proceedToCheckout()
                      },
                    },
                  },
                  [_vm._v("Перейти к оплате")]
                ),
              ]),
            ])
          : _vm.data["Статус"] === "Оплачен"
          ? _c("div", { staticClass: "form-paid" }, [
              _c("h4", [_vm._v("Заказ оплачен")]),
              _vm._v(" "),
              _c("div", [
                _c(
                  "div",
                  { staticClass: "details" },
                  [
                    _c("div", [_vm._v("Номер заказа")]),
                    _vm._v(" "),
                    _c("div", [_vm._v(_vm._s(_vm.data["Номер"]))]),
                    _vm._v(" "),
                    _vm._l(_vm.data["Реквизиты"], function (input) {
                      return [
                        _c("div", [_vm._v(_vm._s(input["Имя"]) + ":")]),
                        _vm._v(" "),
                        _c("div", [_vm._v(_vm._s(input["Значение"]))]),
                      ]
                    }),
                  ],
                  2
                ),
              ]),
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.data["Чек"]
          ? _c(
              "a",
              {
                staticClass: "link check",
                attrs: { href: _vm.data["Чек"]["Ссылка"], target: "_blank" },
              },
              [_vm._v("Кассовый чек")]
            )
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c("div", [
        _c("h2", [_vm._v(_vm._s(_vm.data["Услуга"]["Наименование"]))]),
        _vm._v(" "),
        _c("div", { staticClass: "image_specification" }, [
          _c("div", [
            _c("img", {
              attrs: {
                src: _vm._f("imagePath")(_vm.data["Услуга"]["Картинка"]),
              },
            }),
          ]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "specification" },
            [
              _vm._l(_vm.data["Услуга"]["Характеристики"], function (item) {
                return [
                  _c("div", [_vm._v(_vm._s(item[0]))]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(item[1]))]),
                ]
              }),
            ],
            2
          ),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "options" },
          [
            _vm._l(_vm.data["Услуга"]["Опции"], function (item) {
              return [
                _c("div", [_vm._v(_vm._s(item[0]))]),
                _vm._v(" "),
                _c("div", [_vm._v(_vm._s(item[1]))]),
              ]
            }),
            _vm._v(" "),
            _vm.isCalendarMode
              ? [
                  _c("div", [_vm._v("Время записи (Смена)")]),
                  _vm._v(" "),
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.data["Смена"]["Дата"]) +
                        " " +
                        _vm._s(_vm.data["Смена"]["Наименование"])
                    ),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.isAmountMode
              ? [
                  _c("div", [_vm._v("Количество")]),
                  _vm._v(" "),
                  _c("div", [_vm._v(_vm._s(_vm.data["Услуга"]["Количество"]))]),
                ]
              : _vm._e(),
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "amount" }, [
          _vm._v(_vm._s(_vm._f("currency")(_vm.cost))),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }