<template>
  <div class="form-calendar">
    <div @click="isOpen = false" :class="{open: isOpen}" class="overlay"></div>
    <div class="wrapper">
      <div @click="toggleCalendar()" class="trigger item">
        <div class="selected-date">
          <span v-if="value === null">Дата</span>
          <span v-else>{{ value | formatDate }}</span>
        </div>
        <div :class="{open: isOpen}" class="arrow"><i class="fas fa-chevron-down"></i></div>
      </div>
      <div :class="{open: isOpen}" class="calendar">
        <template v-if="isOpen">
          <div class="year">
            <button @click.prevent="calendarDate.year--"><</button>
            <div>{{ calendarDate.year }}</div>
            <button @click.prevent="calendarDate.year++">></button>
          </div>
          <div class="month">
            <button @click.prevent="decrementMonth()"><</button>
            <div>{{ calendarDate.month | longMonth }}</div>
            <button @click.prevent="incrementMonth()">></button>
          </div>
          <div class="days">
            <div>ПН</div>
            <div>ВТ</div>
            <div>СР</div>
            <div>ЧТ</div>
            <div>ПТ</div>
            <div>СБ</div>
            <div>ВС</div>
            <div v-for="n in indent"></div>
            <button v-for="day in daysInMonth" @click="selectDate(day)"
                    :disabled="workDays === null || !workDays.has(day)">
              {{ day }}
            </button>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'workDays'],
  data() {
    return {
      isOpen: false,
      calendarDate: null
    }
  },
  computed: {
    indent() {
      const date = new Date(this.calendarDate.year, this.calendarDate.month)
      return (date.getDay() + 6) % 7;
    },
    daysInMonth() {
      return 33 - new Date(this.calendarDate.year, this.calendarDate.month, 33).getDate();
    },
    calendarMonthOfTheYear() {
      let date = null;
      if (this.calendarDate) {
        const {year, month} = this.calendarDate
        date = {year, month};
      }
      return date;
    }
  },
  watch: {
    isOpen: function (state) {
      if (state === true) {
        if (this.value)
          this.calendarDate = this.value;
        else {
          const now = new Date();
          this.calendarDate = {
            year: now.getFullYear(),
            month: now.getMonth(),
            day: now.getDate()
          }
        }
      }
    },
    calendarMonthOfTheYear(val) {
      this.$emit("calendar-changed", val)
    }
  },
  methods: {
    selectDate(day) {
      this.calendarDate.day = day;
      this.isOpen = false;

      const {year, month} = this.calendarDate;
      this.$emit('input', {year, month, day})
    },
    toggleCalendar() {
      this.isOpen = !this.isOpen;
    },
    incrementMonth() {
      if (this.calendarDate.month === 11) {
        this.calendarDate.year++
        this.calendarDate.month = 0;
        return;
      }
      this.calendarDate.month++;
    },
    decrementMonth() {
      if (this.calendarDate.month === 0) {
        this.calendarDate.year--;
        this.calendarDate.month = 11;
        return
      }
      this.calendarDate.month--;
    }
  },
  filters: {
    longMonth(val) {
      const months = ['Январь', "Февраль", "Март", "Апрель", "Май", "Июнь", "Июль", "Август", "Сентябрь", "Октябрь", "Ноябрь", "Декабрь"]
      return months[val]
    },
    formatDate(date) {
      const dateAsObject = new Date(date.year, date.month, date.day)
      return dateAsObject.toLocaleDateString();
    }
  }
}
</script>

<style scoped lang="scss">

.container {
  min-width: 280px;
}

.form-calendar {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 100;
}

.overlay.open {
  display: block;
}

.wrapper {
  position: relative;
}

.trigger {
  border: #DADADA solid 1px;
  border-radius: 2px;
  height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}

.trigger > div {

}

.selected-date {
  padding: 16px 16px 16px 48px;
}

.arrow {
  padding: 16px 24px 16px 16px;

}

.arrow i {
  transition: all 0.5s;
  transform: rotate(180deg)
}

.arrow.open i {
  transform: rotate(0deg)
}

.calendar {
  color: #676767;
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding: 8px;
  border: #DADADA solid 1px;
  border-radius: 2px;
  background: #fff;
  opacity: 0;
  visibility: hidden;
  z-index: 200;
  pointer-events: none;
}

.calendar.open {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.year {
  display: flex;
  justify-content: space-between;
}

.year > * {
  padding: 8px 16px;
}

.year > div:hover {
  background-color: #F4F6F8;
}

.month {
  display: flex;
  justify-content: space-between;
}

.month > * {
  padding: 8px 16px;
}

.month > div:hover {
  background-color: #F4F6F8;
}

.days {
  display: grid;
  grid-template-columns: repeat(7, 1fr);
}

.days > * {
  padding: 2px;
  text-align: center;
}

.days > button {
  background-color: transparent;
}

.days > button:hover {
  background-color: #F4F6F8;
}

.item:hover {
  background-color: #f4f4f4;
  border: 1px solid #777777;
  border-radius: 2px;
}

button {
  color: #676767;
  border: none;
  background-color: transparent;
}

button:hover {
  background-color: #f4f4f4;
}

button:disabled {
  color: #DADADA;
}

</style>