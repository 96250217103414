import ProxyMethod from './proxy-method.js'
import Errors from './errors.js'

const context = {
  errors: Errors
}

const boundMethod = ProxyMethod.bind(context);

export default {
    Admin: {
        Certificate: {
            Commit: function (params) {
                return boundMethod('Admin.Certificate.Commit', params);
            },
            GetState: function (params) {
                return boundMethod('Admin.Certificate.GetState', params);
            }
        },
        IsLoggedIn: function (params) {
            return boundMethod('Admin.IsLoggedIn', params);
        },
        Login: function (params) {
            return boundMethod('Admin.Login', params);
        }
    },
    Internal: {
        Ping: function (params) {
            return boundMethod('Internal.Ping', params);
        }
    },
    V1: {
        BackgroundPictures: {
            List: function (params) {
                return boundMethod('V1.BackgroundPictures.List', params);
            }
        },
        Calendar: {
            GetCalendar: function (params) {
                return boundMethod('V1.Calendar.GetCalendar', params);
            },
            OpeningHours: function (params) {
                return boundMethod('V1.Calendar.OpeningHours', params);
            }
        },
        DirectPayments: {
            CreateOrder: function (params) {
                return boundMethod('V1.DirectPayments.CreateOrder', params);
            },
            FindByContractNumber: function (params) {
                return boundMethod('V1.DirectPayments.FindByContractNumber', params);
            }
        },
        Error: {
            AddUserMessage: function (params) {
                return boundMethod('V1.Error.AddUserMessage', params);
            },
            GetError: function (params) {
                return boundMethod('V1.Error.GetError', params);
            },
            GetList: function (params) {
                return boundMethod('V1.Error.GetList', params);
            },
            RegisterError: function (params) {
                return boundMethod('V1.Error.RegisterError', params);
            }
        },
        Favorites: {
            Add: function (params) {
                return boundMethod('V1.Favorites.Add', params);
            },
            Delete: function (params) {
                return boundMethod('V1.Favorites.Delete', params);
            },
            GetState: function (params) {
                return boundMethod('V1.Favorites.GetState', params);
            },
            List: function (params) {
                return boundMethod('V1.Favorites.List', params);
            }
        },
        FooterSections: {
            GetTree: function (params) {
                return boundMethod('V1.FooterSections.GetTree', params);
            }
        },
        Order: {
            Create: function (params) {
                return boundMethod('V1.Order.Create', params);
            },
            GetAll: function (params) {
                return boundMethod('V1.Order.GetAll', params);
            },
            GetStatus: function (params) {
                return boundMethod('V1.Order.GetStatus', params);
            },
            SaveDetails: function (params) {
                return boundMethod('V1.Order.SaveDetails', params);
            }
        },
        Pages: {
            GetPage: function (params) {
                return boundMethod('V1.Pages.GetPage', params);
            }
        },
        PaymentGateway: {
            Link: function (params) {
                return boundMethod('V1.PaymentGateway.Link', params);
            }
        },
        Sections: {
            GetSections: function (params) {
                return boundMethod('V1.Sections.GetSections', params);
            }
        },
        Service: {
            GetRecommendations: function (params) {
                return boundMethod('V1.Service.GetRecommendations', params);
            },
            GetService: function (params) {
                return boundMethod('V1.Service.GetService', params);
            },
            GetServices: function (params) {
                return boundMethod('V1.Service.GetServices', params);
            }
        },
        User: {
            Authenticate: function (params) {
                return boundMethod('V1.User.Authenticate', params);
            },
            AuthenticateOrRegister: function (params) {
                return boundMethod('V1.User.AuthenticateOrRegister', params);
            },
            GetAccessToken: function (params) {
                return boundMethod('V1.User.GetAccessToken', params);
            },
            GetInfo: function (params) {
                return boundMethod('V1.User.GetInfo', params);
            },
            LogOut: function (params) {
                return boundMethod('V1.User.LogOut', params);
            },
            SendRegistrationEmail: function (params) {
                return boundMethod('V1.User.SendRegistrationEmail', params);
            },
            UpdateInfo: function (params) {
                return boundMethod('V1.User.UpdateInfo', params);
            }
        }
    }
}
