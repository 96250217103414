<template>
  <footer v-if="footerTree">
    <div class="container">
      <div class="main">
        <div class="general-info">
          <div v-for="group in other">
            <h4>{{ group["Наименование"] }}</h4>
            <div v-for="item in group['Элементы']">
              <router-link :to="{ name: 'page', params: { id: item['shortname'] || item['ГУИД'] }}">
                {{ item["Наименование"] }}
              </router-link>
            </div>
          </div>
        </div>
      </div>
      <hr>
      <div class="subsection">
        <div><img height="50" src="@/assets/images/logo-letters.svg" alt=""></div>
        <div v-for="item in terms">
          <router-link :to="{ name: 'page', params: { id: item['shortname'] || item['ГУИД'] }}">{{
              item["Наименование"]
            }}
          </router-link>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "footer-component",
  data() {
    return {}
  },
  computed: {
    footerTree() {
      return this.$store.state.footerTree;
    },
    terms() {
      return this.footerTree.find((it) => it['shortname'] === 'terms')['Элементы'];
    },
    other() {
      return this.footerTree.find((it) => it['shortname'] === 'other')['Элементы'];
    }
  },
  methods: {
    async loadData() {
      if (!this.$store.state.footerTree)
        this.$store.commit('setFooterTree', await this.$remoteCalls.V1.FooterSections.GetTree());
    }
  },
  created() {
    this.loadData();
  }
}
</script>

<style scoped lang="scss">
footer {
  background-color: #ECECEC;
  padding-top: 26px;
}

h4 {
  font-family: 'Montserrat', sans-serif;
  font-size: 1.125rem;
  color: black;
}

a {
  font-family: 'Roboto', sans-serif;
  text-decoration: none;
  color: #8E8E8E;
  line-height: 24px;
}

main {
  display: flex;
  justify-content: space-between;
  margin: 20px -8px 48px;

  > div {
    margin: 8px;
    flex-grow: 1;
  }
}

.general-info {
  display: flex;
  justify-content: space-between;
  margin: 0 -16px 64px;

  > div {
    margin: 0 16px;
  }

  > div > div {
    margin: 8px 0;
  }
}

.subsection {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 56px -8px;

  > div {
    margin: 8px 8px;
  }
}

@media (max-width: $tablet-width) {
  .general-info {
    flex-direction: column;
    align-items: center;

    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  .subsection {
    flex-direction: column;
  }
}


</style>