var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "box" }, [
    _c("div", { staticClass: "form" }, [
      _c("div", [_vm._v("Вы знаете секрет?")]),
      _vm._v(" "),
      _c("input", {
        directives: [
          {
            name: "model",
            rawName: "v-model",
            value: _vm.secret,
            expression: "secret",
          },
        ],
        attrs: { type: "password" },
        domProps: { value: _vm.secret },
        on: {
          input: function ($event) {
            if ($event.target.composing) {
              return
            }
            _vm.secret = $event.target.value
          },
        },
      }),
      _vm._v(" "),
      _c("button", { staticClass: "btn", on: { click: _vm.login } }, [
        _vm._v("Войти"),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }