<template>
  <div class="shopfront-content">
    <background-images></background-images>
    <site-header-component></site-header-component>
    <router-view/>
    <footer-component></footer-component>
  </div>
</template>

<script>

import UserComponent from '@/components/common/user'
import BackgroundImages from '@/components/common/background-images'

export default {
  components: {
    'user-component': UserComponent,
    'background-images': BackgroundImages
  }
}

</script>

<style scoped lang="scss">

.header-wrapper {
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.splitter {
  display: flex;
  justify-content: space-between;
  height: 56px;
  align-items: center;
}

.right-menu {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
</style>