<template>
  <div>
    <div ref="recommendation" class="shopfront-recommendation-wrapper">
      <div class="container">
        <div class="shopfront-recommendation-header">Рекомендуем</div>
        <div class="shopfront-recommendation-tiles" style="overflow-x: auto; scrollbar-width: none">
          <a v-for="item in recommendations" class="shopfront-recommendation-tile" target="_blank"
             :href="item['Ссылка']" :title="item['Наименование']">
            <h3>{{ item["Наименование"] }}</h3>
            <img :src="item['Изображение'] | imagePath"/>
          </a>
        </div>
      </div>
    </div>
    <div class="container">
      <breadcrumbs-component :chain="breadcrumbs"></breadcrumbs-component>
      <div class="shopfront-services-header">Каталог услуг</div>
      <div v-if="currentSection" class="shopfront-services-tiles">
        <div v-for="item in currentSection['Элементы']" class="shopfront-services-tile">
          <router-link v-if="item['Вид'] === 'Раздел'" :to="{ name: 'section', params: { id: item['ГУИД'] } }"
                       :title="item['Имя']">
            <h3>{{ item['Имя'] }}</h3>
            <img :src="item['Миниатюра'] | imagePath"/>
          </router-link>
          <a v-if="item['Вид'] === 'Сайт'" target="_blank" :href="item['Url']" :title="item['Имя']">
            <h3>{{ item['Имя'] }}</h3>
            <img :src="item['Миниатюра'] | imagePath"/>
          </a>
          <router-link v-if="item['Вид'] === 'Магазин'" :to="{ name: 'store', params: { storeId: item['shortname'] || item['ГУИД'] } }"
                       :title="item['Имя']">
            <h3>{{ item['Имя'] }}</h3>
            <img :src="item['Миниатюра'] | imagePath"/>
          </router-link>
        </div>
      </div>
      <div v-else>Получение данных...</div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      sectionRoot: null,
      sectionsByCode: {},
      path: [],
      recommendations: [],
      settings: {
        "variableWidth": true
      }
    }
  },
  computed: {
    breadcrumbs() {
      let path = []
      let parent = function (child) {
        if (!(child && child["ГУИД"])) return;
        path.push({name: child["Имя"], to: {name: 'section', params: {id: child["ГУИД"]}}})
        if (child["Родитель"]) parent(child["Родитель"])
      }
      parent(this.currentSection)
      return path.reverse();
    },
    sectionId: function () {
      return this.$route.params.id;
    },
    currentSection() {
      return this.sectionsByCode[this.sectionId] || this.sectionRoot
    }
  },
  watch: {
    currentSection() {
      this.path = this.pathBySection()
    }
  },
  created() {
    this.loadSections();
    this.loadRecommendations();
  },
  methods: {
    pathBySection() {
      let path = []
      let parent = function (child) {
        if (!(child && child["ГУИД"])) return;
        path.push({name: child["Имя"], id: child["ГУИД"]})
        if (child["Родитель"]) parent(child["Родитель"])
      }
      parent(this.currentSection)
      return path.reverse();
    },
    async loadSections() {
      try {
        let sectionsHierarchy = await this.$remoteCalls.V1.Sections.GetSections()
        let sectionsByCode = {}

        let sectionRoot = {"Элементы": sectionsHierarchy}

        let traverseHierarchically = function (parent) {
          (parent["Элементы"] || []).forEach((item) => {
            item["Родитель"] = parent
            sectionsByCode[item['ГУИД']] = item;
            traverseHierarchically(item);
          })
        }

        traverseHierarchically(sectionRoot)

        this.sectionsByCode = sectionsByCode
        this.sectionRoot = sectionRoot
      } catch (e) {
        this.$handleError(e)
      }
    },
    async loadRecommendations() {
      try {
        this.recommendations = await this.$remoteCalls.V1.Service.GetRecommendations();
      } catch (e) {
        this.$handleError(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.breadcrumbs {
  margin: 16px 0;
}
</style>