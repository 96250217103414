<template>
  <div>
    <div @click="isOpen = false" :class="{open: isOpen}" class="custom-select-overlay"></div>
    <div class="custom-select-wrapper" @click="isOpen = !isOpen">
      <div :class="{open: isOpen}" class="custom-select">
        <div class="custom-select__trigger item">
          <div v-if="selectedItem" class="custom-option" :class="{ selected: !!selectedItem }">
            {{ selectedItem.name }}
          </div>
          <div class="custom-option" v-else>
            <slot></slot>
          </div>
          <div class="commands">
            <i class="fas fa-chevron-down"></i>
            <i v-show="!!selectedItem" @click.stop="clearItem()" class="fas fa-times"></i>
          </div>
        </div>
        <div class="custom-options">
          <span v-for="item of options" @click="$emit('input', item.value)"
                :class="{ disabled: item.disabled, selected: item.selected }" class="custom-option item">
            {{ item.name }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ['value', 'options', 'defaultText'],
  data() {
    return {
      isOpen: false
    }
  },
  computed: {
    selectedItem() {
      return this.options.find((item) => {
        return item.value === this.value
      })
    }
  },
  methods: {
    clearItem() {
      this.$emit('input', null)
    }
  },
}
</script>

<style lang="scss">

.custom-select-wrapper {
  position: relative;
  user-select: none;
  width: 100%;
  min-width: 250px;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
}

.custom-select {
  position: static;
  display: flex;
  flex-direction: column;
  border: #DADADA solid 1px;
  border-radius: 2px;
}

.custom-select__trigger {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 16px 0 0;
  color: #676767;
  height: 48px;
  background: #ffffff;
  cursor: pointer;
}

.custom-options {
  position: absolute;
  display: block;
  top: 100%;
  left: 0;
  right: 0;
  border: 1px solid #DADADA;
  border-radius: 2px;
  border-top: 0;
  background: #fff;
  transition: all 0.5s;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 3;
}

.custom-select.open .custom-options {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.custom-option {
  position: relative;
  display: block;
  padding: 16px 16px 16px 48px;
  font-size: 1rem;
  color: #676767;
  cursor: pointer;
  transition: all 0.5s;
}

.selected {
  padding-left: 16px;
  color: black;
}

.selected::before {
  display: inline-block;
  font-family: "Font Awesome 6 Free";
  font-weight: 900;
  content: "\f00c";
  width: 32px;
}

.custom-option.disabled {
  cursor: default;
  pointer-events: none;
  color: #DADADA;
}

.arrow {
  margin-left: 17px;
  width: 14px;
  height: 8.5px;

  background-image: url("data:image/svg+xml,%3Csvg width='14' height='9' viewBox='0 0 14 9' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M6.45593 8.27079L0.225371 2.04019C-0.0751238 1.7397 -0.0751238 1.25252 0.225371 0.952059L0.952066 0.225364C1.25205 -0.0746183 1.73823 -0.0751953 2.03892 0.224081L7.00002 5.16194L11.9611 0.224081C12.2618 -0.0751953 12.748 -0.0746183 13.0479 0.225364L13.7746 0.952059C14.0751 1.25255 14.0751 1.73973 13.7746 2.04019L7.54407 8.27076C7.24361 8.57125 6.75643 8.57125 6.45593 8.27079Z' fill='%23777777'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.item {
  border: 1px solid transparent;
  box-sizing: border-box;
}

.item:hover {
  background-color: #f4f4f4;
  border: 1px solid #777777;
  border-radius: 2px;
  box-sizing: border-box;
}

.commands {
  display: flex;
  align-items: center;

  > * {
    padding: 8px;
  }
}

.custom-select-overlay {
  background-color: transparent;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: none;
  z-index: 2;
}

.custom-select-overlay.open {
  display: block;
}

</style>