var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.chain
    ? _c(
        "div",
        { staticClass: "breadcrumbs" },
        [
          _c("router-link", { attrs: { to: { name: "root" } } }, [
            _vm._v("Главная"),
          ]),
          _vm._v(" "),
          _vm._l(_vm.chain, function (item) {
            return [
              _c("i", { staticClass: "fas fa-chevron-right" }),
              _vm._v(" "),
              _c("router-link", { attrs: { to: item.to } }, [
                _vm._v(_vm._s(item.name)),
              ]),
            ]
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }