var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.images.length > 0
    ? _c(
        "div",
        { staticClass: "background-images", on: { mousemove: _vm.mouseMove } },
        [
          _c(
            "a",
            {
              staticClass: "wrapper",
              attrs: {
                href: _vm.images[_vm.currentImage]["Ссылка"],
                target: "_blank",
              },
            },
            [
              _c("div", { staticClass: "buffer" }, [
                _c("img", {
                  class: [_vm.isCurrentImage(0) ? "front" : "back"],
                  attrs: {
                    src: _vm.isCurrentImage(0)
                      ? _vm.images[_vm.currentImage]["Путь"]()
                      : _vm.images[_vm.previousImage]["Путь"](),
                  },
                }),
                _vm._v(" "),
                _c("img", {
                  class: [_vm.isCurrentImage(1) ? "front" : "back"],
                  attrs: {
                    src: _vm.isCurrentImage(1)
                      ? _vm.images[_vm.currentImage]["Путь"]()
                      : _vm.images[_vm.previousImage]["Путь"](),
                  },
                }),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "left-button button",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearDelay(_vm.prevImage)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-angle-left" })]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  staticClass: "right-button button",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.clearDelay(_vm.nextImage)
                    },
                  },
                },
                [_c("i", { staticClass: "fas fa-angle-right" })]
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "dots" },
            _vm._l(_vm.images, function (image, index) {
              return _c("a", {
                staticClass: "dot",
                class: { "selected-image": _vm.currentImage === index },
                attrs: { href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    _vm.currentImage = index
                  },
                },
              })
            }),
            0
          ),
        ]
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }