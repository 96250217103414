<template>
  <div>
    <h1>Упс! Что-то пошло не так</h1>
    <p v-if="uploading">Отправляем данные программистам...</p>
    <p v-else>Информации об ошибке уже отправлена в службу поддержки. Опишите события, которые предшестовали ошибке. Это
      позволит нам быстрее найти и исправить ошибку.</p>
    <p><textarea v-model="message" style="width: 100%; height: 300px"></textarea></p>
    <p>
      <button @click="uploadUserMessage()">Отправить</button>
      <span v-if="userMessageState === 'uploading'">Отправляем данные...</span>
      <span v-else-if="userMessageState === 'complete'">Сообщение отправлено. Спасибо за вашу помощь</span>
    </p>
    <p>
      <router-link :to="{ name: 'root' }">Вернуться на главную страницу</router-link>
    </p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      uploading: true,
      message: '',
      userMessageState: 'none',
      secure_id: null
    }
  },
  watch: {
    $route() {
      this.uploadError();
    }
  },
  created() {
    this.uploadError();
  },
  methods: {
    async uploadError() {
      try {
        if (this.$store.state.lastError === null) {
          await this.$router.push({name: 'root'})
          return;
        }

        this.uploading = true;

        this.secure_id = await this.$remoteCalls.V1.Error.RegisterError({
          error: this.$store.state.lastError
        });
      } finally {
        await this.$store.commit('setLastError', null)
        this.uploading = false;
      }
    },
    async uploadUserMessage() {
      try {
        this.userMessageState = 'uploading';
        await this.$remoteCalls.V1.Error.AddUserMessage({
          secure_id: this.secure_id,
          message: this.message
        });
      } finally {
        this.userMessageState = 'complete';
      }
    }
  }
}
</script>