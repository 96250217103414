var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.userInfo
    ? _c(
        "div",
        { staticClass: "menu" },
        [
          _c(
            "router-link",
            { staticClass: "main-link", attrs: { to: { name: "my-office" } } },
            [
              _vm.userInfo
                ? _c("div", [_vm._v(_vm._s(_vm.userInfo["Наименование"]))])
                : _vm._e(),
              _vm._v(" "),
              _c("img", {
                attrs: { src: require("@/assets/images/user.svg"), alt: "" },
              }),
            ]
          ),
          _vm._v(" "),
          _c("ul", [
            _c(
              "li",
              { staticClass: "item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "profile-link link",
                    attrs: { to: { name: "my-office" } },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/my-office.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("Личный кабинет")]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "profile-link link",
                    attrs: { to: { name: "orders" } },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/orders.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("Мои заказы")]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "item" },
              [
                _c(
                  "router-link",
                  {
                    staticClass: "profile-link link",
                    attrs: { to: { name: "favorites" } },
                  },
                  [
                    _c("img", {
                      attrs: {
                        src: require("@/assets/images/favorites.svg"),
                        alt: "",
                      },
                    }),
                    _vm._v(" "),
                    _c("div", [_vm._v("Избранное")]),
                  ]
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm._m(0),
            _vm._v(" "),
            _c(
              "li",
              { staticClass: "item" },
              [
                _c(
                  "router-link",
                  { staticClass: "link", attrs: { to: { name: "logout" } } },
                  [_vm._v("\n        Выйти\n      ")]
                ),
              ],
              1
            ),
          ]),
        ],
        1
      )
    : _c(
        "router-link",
        { staticClass: "main-link", attrs: { to: _vm.loginPath() } },
        [
          _c("div", [_vm._v("Вход")]),
          _vm._v(" "),
          _c("img", {
            attrs: {
              src: require("@/assets/images/image-not-found.svg"),
              alt: "",
            },
          }),
        ]
      )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("div", { staticClass: "separator" })])
  },
]
render._withStripped = true

export { render, staticRenderFns }