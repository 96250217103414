<template>
  <div>
    <router-link :to="{ name: 'my-office' }">Персональные данные</router-link>
    <router-link :to="{ name: 'orders' }">Заказы</router-link>
  </div>
</template>

<script>
export default {}
</script>

<style scoped lang="scss">

div {
  display: flex;
  margin: 0 -16px;
}

a {
  display: block;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: #DADADA;
  min-width: 350px;
  padding: 12px 8px;
  margin: 16px;
  border: 1px solid #DADADA;
}

.router-link-active {
  color: black;
}

</style>