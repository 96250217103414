import '@/styles/style.scss'
import '@/plugins'
import '@/components'

import Vue from 'vue'

import errorService from "@/error-service";
Vue.config.errorHandler = errorService

import App from '@/app'
const app = new Vue(App);
app.$mount('#app')