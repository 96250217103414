<template>
  <div class="header-container">
    <div class="header-wrapper">
      <div class="site-header">
        <div class="container">
          <router-link :to="{ name: 'root'}"><img class="logo" src="@/assets/images/logo-market.svg"/></router-link>
          <div v-if="hideRightMenu !== true" class="right-menu">
            <user-component></user-component>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserComponent from '@/components/common/user'

export default {
  props: {
    hideRightMenu: Boolean
  },
  components: {
    'user-component': UserComponent
  }
}
</script>

<style scoped lang="scss">

.header-container {
  position: absolute;
  top: 56px;
  width: 100%;
}

.header-wrapper {
  width: 100%;
}

.site-header {
  height: 56px;
  width: 100%;

  background-color: white;
  display: flex;
  align-items: center;

  > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
}

.logo {
  position: relative;
  display: block;
}

@media screen and (max-width: $container-width) {
  .logo {
    width: 150px;
    height: 24px;
  }
}

@media screen and (max-width: $tablet-width) {
  .header-container {
    top:0;
  }
}


</style>