<template>
  <div class="wrapper">
    <div class="modal">
      <h1>Техническое обслуживание</h1>
      В настоящее время мы проводим плановое техническое обслуживание. Мы постараемся завершить работы
      как можно скорее. Приносим извинения за доставленные неудобства
    </div>
  </div>
</template>

<script>
export default {
  name: "maintenance"
}
</script>

<style scoped>
.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;

}

.modal {
  font-weight: 700;
  font-size: .875rem;
  max-width: 600px;
}

.modal h1 {
  text-align: center;
}
</style>