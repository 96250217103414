<template>
  <div v-if="chain" class="breadcrumbs">
    <router-link :to="{ name: 'root'}">Главная</router-link>
    <template v-for="item in chain">
      <i class="fas fa-chevron-right"></i>
      <router-link :to="item.to">{{ item.name }}</router-link>
    </template>
  </div>
</template>

<script>
export default {
  name: "breadcrumbs",
  props: ['chain'],
  data() {
    return {
    }
  }
}
</script>

<style scoped lang="scss">

.breadcrumbs {
  color: #777777;
  font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  padding-bottom: 16px;
  border-bottom: 1px solid #DADADA;

  a {
    margin: 0 8px;
    text-decoration: none;
    color: black;
  }
}

</style>