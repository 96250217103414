<template>
  <div class="box">
    <div class="form">
      <div>Вы знаете секрет?</div>
      <input v-model="secret" type="password">
      <button @click="login" class="btn">Войти</button>
    </div>
  </div>
</template>

<script>
export default {
  name: "admin-login",
  data() {
    return {
      secret: '',
    }
  },
  methods: {
    async login() {
      let isAdmin = await this.$remoteCalls.Admin.Login({secret: this.secret});
      this.$store.commit('setAdmin', isAdmin)
      if (isAdmin) {
        const redirect = this.$route.query.redirect || {name: 'admin-dashboard'}
        await this.$router.replace(redirect)
      }
    }
  }
}
</script>

<style scoped>
.box {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background: #edf2f7;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: #ffffff;
  padding: 32px;
  border-radius: 12px;
  box-shadow: 0px 5px 10px 2px rgba(34, 60, 80, 0.2);
}
</style>