<template>
  <div v-if="images.length > 0" @mousemove="mouseMove" class="background-images">
    <a :href="images[currentImage]['Ссылка']" target="_blank" class="wrapper">
      <div class="buffer">
        <img :src="isCurrentImage(0) ? images[currentImage]['Путь']() : images[previousImage]['Путь']()"
             :class="[isCurrentImage(0) ? 'front' : 'back']">
        <img :src="isCurrentImage(1) ? images[currentImage]['Путь']() : images[previousImage]['Путь']()"
             :class="[isCurrentImage(1) ? 'front' : 'back']">
      </div>
      <a @click.prevent="clearDelay(prevImage)" href="#" class="left-button button">
        <i class="fas fa-angle-left"></i>
      </a>
      <a @click.prevent="clearDelay(nextImage)" href="#" class="right-button button">
        <i class="fas fa-angle-right"></i>
      </a>
    </a>
    <div class="dots">
      <a v-for="(image, index) in images" @click.prevent="currentImage = index"
         :class="{'selected-image': currentImage === index}" href="#" class="dot"></a>
    </div>
  </div>
</template>

<script>
import {delay, throttle} from 'lodash'
import DayImage from '@/assets/images/header-background-day.svg'
import NightImage from '@/assets/images/header-background-night.svg'

export default {
  data() {
    return {
      timer: null,
      bufferIndex: 0,
      previousImage: 0,
      currentImage: 0,
      images: []
    }
  },
  async created() {
    const defaultBackgroundImages = [{
      "Путь": () => {
        return this.isDay() ? DayImage : NightImage
      }
    }]

    let images = (await this.$remoteCalls.V1.BackgroundPictures.List())
        .map((it) => Object.assign(it, {
          "Путь": () => {
            return this.$options.filters.imagePath(it["Картинка"])
          }
        }));
    this.images = defaultBackgroundImages.concat(images)

    this.delayRotation();
  },
  methods: {
    isDay() {
      const hours = (new Date()).getHours();
      return hours >= 6 && hours < 18;
    },
    rotate() {
      this.nextImage();
      this.delayRotation();
    },
    delayRotation() {
      this.timer = delay(this.rotate, 5000)
    },
    clearDelay(func) {
      clearTimeout(this.timer);
      func()
      this.delayRotation();
    },
    mouseMove: throttle(function() {
      clearTimeout(this.timer);
      this.delayRotation()
    }, 1000),
    prevImage() {
      this.previousImage = this.currentImage;
      this.currentImage = (this.images.length + this.currentImage - 1) % this.images.length
      this.swapBuffer();
    },
    nextImage() {
      this.previousImage = this.currentImage;
      this.currentImage = (this.currentImage + 1) % this.images.length
      this.swapBuffer();
    },
    swapBuffer() {
      this.bufferIndex = (this.bufferIndex + 1) % 2
    },
    isCurrentImage(index) {
      return this.bufferIndex === index;
    }
  }
}
</script>

<style scoped lang="scss">
body {
  margin: 0
}

img {
  border-style: none;
}

.wrapper {
  position: relative;
  display: block;
  margin: 0 auto;
  max-width: $container-width;
}

@keyframes fade-out {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fade-in {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.buffer {
  position: relative;
  width: 100%;
  height: calc(100vw / 2);
  max-height: 600px;

  > img {
    position: absolute;
    top: 0;
    left: 0;
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center top;
  }

  > img.front {
    animation: fade-out 1s;
    opacity: 1;
  }

  > img.back {
    animation: fade-in 1s;
    opacity: 0;
  }
}

.left-button {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.right-button {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100px;

  display: flex;
  align-items: center;
  justify-content: center;
}

.button {
  color: #ffffff;
  font-size: 2rem;
  text-decoration: none;
}

.button:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.dots {
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: #F4F6F8;

  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
}

.dot {
  display: block;
  width: 8px;
  height: 8px;
  background-color: #BEBEBE;
  margin: 5px;
  border-radius: 50%;
}

.dot.selected-image {
  background-color: #841DD4;
}

.dot:hover {
  background-color: #841DD4;
}

</style>