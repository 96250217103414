var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel" }, [
    _c("h2", [_vm._v("Ошибки")]),
    _vm._v(" "),
    _c("table", [
      _vm._m(0),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.errors, function (it) {
            return [
              _c(
                "tr",
                {
                  staticStyle: { cursor: "pointer" },
                  on: {
                    click: function ($event) {
                      return _vm.showError(it.id)
                    },
                  },
                },
                [
                  _c(
                    "td",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { nowrap: "" },
                    },
                    [_vm._v(_vm._s(it.id))]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { nowrap: "" },
                    },
                    [_vm._v(_vm._s(_vm._f("dateTime")(it.created_at)))]
                  ),
                  _vm._v(" "),
                  _c(
                    "td",
                    {
                      staticStyle: { "text-align": "center" },
                      attrs: { nowrap: "" },
                    },
                    [_vm._v(_vm._s(it.name))]
                  ),
                  _vm._v(" "),
                  it.message.length > 150
                    ? _c("td", [
                        _vm._v(
                          _vm._s(_vm.truncate(it.message, 150, true)) + " …"
                        ),
                      ])
                    : _c("td", [_vm._v(_vm._s(it.message))]),
                ]
              ),
              _vm._v(" "),
              it.user_message instanceof String &&
              it.user_message.trim().length > 0
                ? _c("tr", [
                    _c("td", { attrs: { colspan: "4" } }, [
                      _c("strong", [_vm._v("Сообщение пользователя:")]),
                      _vm._v(" " + _vm._s(it.user_message)),
                    ]),
                  ])
                : _vm._e(),
            ]
          }),
        ],
        2
      ),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("Идентификатор")]),
        _vm._v(" "),
        _c("th", { attrs: { nowrap: "" } }, [_vm._v("Дата создания")]),
        _vm._v(" "),
        _c("th", [_vm._v("Имя")]),
        _vm._v(" "),
        _c("th", [_vm._v("Описание")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }