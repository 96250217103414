var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "shop-header" }, [
      _vm._v(_vm._s(_vm.data["НаименованиеРаздела"] || "Раздел")),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "shop-service-content" },
      [
        _c("breadcrumbs-component", { attrs: { chain: _vm.breadcrumbs } }),
        _vm._v(" "),
        _c("div", { staticClass: "service-container" }, [
          _c("h1", { ref: "header", staticClass: "header" }, [
            _vm._v(_vm._s(_vm.data["Наименование"] || "Услуга")),
          ]),
          _vm._v(" "),
          _c("div", [
            _c("div", { staticClass: "service__image" }, [
              _c("img", {
                attrs: { src: _vm._f("imagePath")(_vm.imageFirst), alt: "" },
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "service__specification" },
                [
                  _vm._l(_vm.data["Характеристики"], function (item) {
                    return [
                      _c(
                        "div",
                        { staticClass: "service__specification-name" },
                        [_c("span", [_vm._v(_vm._s(item["Наименование"]))])]
                      ),
                      _vm._v(" "),
                      _c("div", [_vm._v(_vm._s(item["Значения"].join(", ")))]),
                    ]
                  }),
                ],
                2
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", [
            _c(
              "div",
              { staticClass: "service__parameters" },
              [
                _vm._l(_vm.data["Опции"], function (itemSelect) {
                  return _c(
                    "form-select",
                    {
                      key: itemSelect["ГУИД"],
                      attrs: {
                        value: _vm.selectedValues[itemSelect["ГУИД"]],
                        options: _vm._f("adaptList")(
                          itemSelect["Значения"],
                          itemSelect["ГУИД"],
                          _vm.allowedOptions,
                          _vm.selectedValues[itemSelect["ГУИД"]]
                        ),
                      },
                      on: {
                        input: function ($event) {
                          return _vm.selectOption(itemSelect["ГУИД"], $event)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(itemSelect["Наименование"]) +
                          "\n          "
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _vm.isCalendarMode
                  ? _c(
                      "div",
                      { staticClass: "showtimes" },
                      [
                        _c("div", [_vm._v("Время сеанса")]),
                        _vm._v(" "),
                        _c("form-calendar", {
                          attrs: { "work-days": _vm.calendar.workDays },
                          on: {
                            "calendar-changed": function ($event) {
                              return _vm.calendarChanged($event)
                            },
                          },
                          model: {
                            value: _vm.calendar.selectedDate,
                            callback: function ($$v) {
                              _vm.$set(_vm.calendar, "selectedDate", $$v)
                            },
                            expression: "calendar.selectedDate",
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "form-select",
                          {
                            attrs: {
                              options: _vm._f("adaptOpeningHours")(
                                _vm.calendar.openingHours
                              ),
                            },
                            model: {
                              value: _vm.calendar.selectedHour,
                              callback: function ($$v) {
                                _vm.$set(_vm.calendar, "selectedHour", $$v)
                              },
                              expression: "calendar.selectedHour",
                            },
                          },
                          [_vm._v("Сеансы\n            ")]
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.isAmountMode
                  ? _c("div", { staticClass: "amount" }, [
                      _c("label", { attrs: { for: "amount" } }, [
                        _vm._v("Количество"),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number",
                            value: _vm.amount,
                            expression: "amount",
                            modifiers: { number: true },
                          },
                        ],
                        attrs: {
                          type: "number",
                          min: "1",
                          step: "1",
                          id: "amount",
                        },
                        domProps: { value: _vm.amount },
                        on: {
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.amount = _vm._n($event.target.value)
                          },
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ])
                  : _vm._e(),
                _vm._v(" "),
                _c("div", [
                  _vm.clearanceCompleted
                    ? _c("div", { staticClass: "service__amount-container" }, [
                        _c("div", { staticClass: "service__amount-title" }, [
                          _vm._v("Итого"),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "service__amount" }, [
                          _vm._v(_vm._s(_vm._f("currency")(_vm.currentPrice))),
                        ]),
                      ])
                    : _c("div", [
                        _vm._v(
                          "\n              Для получения цены выберите опции\n            "
                        ),
                      ]),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "service__amount_favorite_buttons" }, [
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: !_vm.clearanceCompleted },
                      on: {
                        click: function ($event) {
                          return _vm.createOrder()
                        },
                      },
                    },
                    [_vm._v("Оформить заказ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "btn",
                      attrs: { disabled: _vm.updatingFavorite },
                      on: {
                        click: function ($event) {
                          return _vm.toggleFavorite()
                        },
                      },
                    },
                    [
                      _c("i", {
                        staticClass: "fa-xl fa-heart",
                        class: [_vm.favorite ? "fa-solid" : "fa-regular"],
                      }),
                    ]
                  ),
                ]),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "service__description" }, [
            _c("h4", { staticClass: "service__description-title" }, [
              _vm._v("Описание:"),
            ]),
            _vm._v(" "),
            _c("div", {
              domProps: {
                innerHTML: _vm._s(
                  _vm.$options.filters.markdown(_vm.data["Описание"] || "")
                ),
              },
            }),
          ]),
        ]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }