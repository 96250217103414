<template>
  <div>
    <div class="background"></div>
    <div class="header-container">
      <site-header-component hide-right-menu></site-header-component>
    </div>
    <div class="wrapper">
      <div class="form">
        <div v-if="tab === 'links'" class="links">
          <h1>Вход в магазин</h1>
          <a :href="vkontakte"><img src="@/assets/images/vk.svg">
            <div>Войти через Vkontakte</div>
          </a>
          <a :href="google"><img src="@/assets/images/google.svg">
            <div>Войти через Google</div>
          </a>
          <!--<a :href="facebook">
            <img src="@/assets/images/facebook.svg">
            <div>Войти через Facebook</div>
          </a>-->
          <a :href="urfu"><img src="@/assets/images/urfu.png">
            <div>Войти через ЛК УрФУ</div>
          </a>
          <a @click.prevent="enterEmail()" href="#"><img src="@/assets/images/email.svg">
            <div>Войти через email</div>
          </a>
        </div>
        <div v-else-if="tab ==='email'" class="email-form">
          <h1>Вход через email</h1>
          <p>Укажите свой email адрес для входа на сайт</p>
          <input v-model="email" @keyup.enter="sendLetter()" ref="emailInput"/>
          <button @click="sendLetter()" class="btn">Продолжить</button>
          <a @click.prevent="tab = 'links'" href="#">Вернуться ко всем вариантам входа</a>
        </div>
        <div v-else-if="tab === 'emailSent'" class="email-sent">
          <h1>Проверьте свою почту</h1>
          <p>Для завершения входа перейдите по ссылке в отправленном для вас письме на {{ email }} </p>
          <router-link :to="{ name: 'root' }" class="btn">OK</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Base64} from "js-base64";

export default {
  data() {
    return {
      tab: 'links',
      email: ''
    }
  },
  created() {
    this.loadUserInfo();
  },
  watch: {},
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    },
    vkontakte() {
      return this.redirectToLoginUri('vkontakte', process.env.VKONTAKTE_CLIENT_ID, process.env.VKONTAKTE_LOGIN_URI)
    },
    google() {
      return this.redirectToLoginUri('google', process.env.GOOGLE_CLIENT_ID, process.env.GOOGLE_LOGIN_URI)
    },
    // facebook() {
    //   return this.redirectToLoginUri('facebook', process.env.FACEBOOK_CLIENT_ID, process.env.FACEBOOK_LOGIN_URI)
    // },
    urfu() {
      return this.redirectToLoginUri('urfu', process.env.URFU_CLIENT_ID, process.env.URFU_LOGIN_URI)
    },
    state() {
      let state = {};

      if (this.$route.query.origin) {
        state['origin'] = this.$route.query.origin;
      }

      if (this.$route.query.redirect) {
        state['redirect'] = this.$route.query.redirect;
      }

      return state;
    }
  },
  methods: {
    async loadUserInfo() {
      if (this.$store.getters.isAuthenticated) {
        if (this.$route.query.origin) {
          const accessToken = await this.$remoteCalls.V1.User.GetAccessToken();
          const pathname = '/login?accessToken=:accessToken&redirect=:redirect'
              .replace(':accessToken', accessToken)
              .replace(':redirect', this.$route.query.redirect)
          window.location.href = this.$route.query.origin + pathname;
        }

        if (this.$route.query.redirect) {
          await this.$router.replace(this.$route.query.redirect)
        }
      }
    },
    redirectUri(service) {
      return process.env.HOST + process.env.OAUTH_CALLBACK_PATH.replace(':service', service);
    },
    redirectToLoginUri(provider, clientId, loginUriTemplate) {
      const redirectUri = encodeURIComponent(this.redirectUri(provider))
      const state = Base64.encode(JSON.stringify(this.state), true)
      return loginUriTemplate.replace(/:client_id/g, encodeURIComponent(clientId))
          .replace(/:redirect_uri/g, redirectUri).replace(/:state/g, state);
    },
    enterEmail() {
      this.tab = 'email'
      this.$nextTick(() => {
        this.$refs.emailInput.focus();
      });
    },
    sendLetter() {
      let emailValidator = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
      if (!emailValidator.test(this.email)) alert("Укажите корректный email")

      let params = {email: this.email, state: this.state}
      this.$remoteCalls.V1.User.SendRegistrationEmail(params)

      this.tab = 'emailSent'
    }
  }
}
</script>

<style scoped lang="scss">

.background {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #F4F6F8 url("@/assets/images/boy.svg") no-repeat bottom 8px right 8px;
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  top: 260px;
  position: relative;
}

.form {
  background-color: white;
  border: 1px solid #DADADA;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.05);
}

.links {
  display: flex;
  flex-direction: column;
  padding: 0 96px 96px;

  > * {
    margin: 8px;
  }

  img {
    display: block;
    height: 15px;
    width: 20px;
    object-fit: contain;
  }

  a {
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 16px;
    text-decoration: none;
    font-weight: 600;
    font-size: 0.875rem;
    color: black;
    border: 1px solid #C7C7C7;
    border-radius: 3px;
    background-color: white;
  }

  a.disabled {
    background-color: #f4f6f8;
    color: #262626;
  }

  a > * {
    margin: 6px;
  }
}

h1 {
  text-align: center;
  font-size: 1.5rem;
  padding: 50px 24px 24px 24px;
}

.site-header {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.email-form {
  display: flex;
  flex-direction: column;
  padding: 0 96px 96px;
  gap: 16px;
}

.email-form input {
  font-family: "Roboto", sans-serif;
  box-sizing: border-box;
  border: #DADADA solid 1px;
  border-radius: 2px;
  padding: 14px 20px;
}

p {
  font-family: "Roboto", sans-serif;
  line-height: 1.5rem;
  text-align: center;
  width: 350px;
}

.email-form a {
  display: block;
  font-family: "Roboto", sans-serif;
  font-size: 0.875rem;
  color: #787878;
  text-decoration: none;
  padding-top: 32px;
  text-align: center;
}

.email-sent {
  display: flex;
  flex-direction: column;
  padding: 0 96px 96px;
  gap: 8px;
}

.email-sent a {
  text-align: center;
}

</style>