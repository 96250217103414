var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "header-background" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header-container" },
      [_c("site-header-component")],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "common-content" },
      [
        _c("div", { staticClass: "container" }, [_c("router-view")], 1),
        _vm._v(" "),
        _c("footer-component"),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }