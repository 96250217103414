<template>
  <div>
    <div class="background">
      <img class="left-image" src="@/assets/images/shop-banner-left-image.svg">
      <img class="right-image" src="@/assets/images/shop-banner-right-image.svg">
    </div>
    <div class="header-container">
      <site-header-component></site-header-component>
    </div>
    <div class="shop-content">
      <div class="container">
        <router-view/>
      </div>
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      data: {},
      showRegistration: false
    }
  },
  computed: {},
  watch: {
    $route: 'init'
  },
  created() {
    this.init()
  },
  methods: {
    init() {
    }
  },
  components: {}
}
</script>

<style scoped lang="scss">

.background {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(31.73vw + 107.20px);
  width: 100%;
  background: linear-gradient(to right, #f4be26, #f1bd4b);
}

.left-image {
  position: absolute;
  top: 0;
  left: 0;
  height: calc(0.24 * 100vw + 62.73px);
}

.right-image {
  position: absolute;
  bottom: 0;
  right: 0;
  height: calc(0.23 * 100vw + 39.55px);
}

@media screen and (max-width: 1600px) {
  .left-image {
    display: none;
  }
}

@media screen and (max-width: $mobile-width) {
  .background {
    height: 208px;
  }

  .left-image {
    height: 138px;
  }

  .right-image {
    height: 113px;
  }
}

@media screen and (max-width: $tablet-width) {
  .left-image {
    top: 56px;
  }
}

@media screen and (min-width: $container-width) {
  .background {
    height: 488px;
  }

  .left-image {
    height: 345px;
  }

  .right-image {
    height: 315px;
  }
}

</style>