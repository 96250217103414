<template>
  <router-view/>
</template>

<script>
import router from '@/router'
import store from '@/store'

export default {
  router,
  store
}
</script>