const PATH = '/api/rpc'

export default async function ProxyMethod(method, params) {
    let requestBody = { method }
    if (params !== undefined)
        requestBody['params'] = params

    const response = await fetch(PATH, {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        body: JSON.stringify(requestBody)
    })

    if (!response.ok) {
        throw new Error("Ошибка сети. Получен некорректный ответ")
    }

    let body = await response.json()

    if ('result' in body)
        return body['result']

    if ("error" in body) {
        const error = body["error"]
        const clazz = this.errors[error['code']]
        if (clazz) throw new clazz(error['message'])
        else throw new Error(`#${error.code} ${error.message}`)
    }

    throw new Error('Unexpected response received')
}