import RemoteMethods from '@/remote/methods'
import RemoteErrors from "@/remote/errors";

import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

import store from '@/store'

import GeneralLayout from '@/layouts/general'
import ShopfrontLayout from '@/layouts/shopfront'
import ShopLayout from '@/layouts/shop'

import Direct from '@/pages/direct'
import Callback from '@/pages/callback'
import Error from '@/pages/error'
import Favorites from '@/pages/favorites'
import LogOut from '@/pages/logout'
import Login from '@/pages/login'
import Maintenance from '@/pages/maintenance'
import MyOffice from '@/pages/my-office'
import Order from '@/pages/order'
import Orders from '@/pages/orders'
import Page from '@/pages/page'
import PersonalData from '@/pages/personal-data'
import Sections from '@/pages/sections'
import Service from '@/pages/service'
import Services from '@/pages/services'

import AdminLogin from '@/pages/admin/login'
import AdminDashboard from '@/pages/admin/dashboard'
import DashboardTile from '@/pages/admin/tile'
import DashboardCertificate from '@/pages/admin/certificate'
import DashboardErrors from "@/pages/admin/errors";
import DashboardError from "@/pages/admin/error";

const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/',
            component: ShopfrontLayout,
            meta: {title: "Витрина УрФУ"},
            children: [
                {path: '', name: 'root', component: Sections},
                {path: 'section/:id', name: 'section', component: Sections}
            ]
        },
        {
            path: '/',
            component: GeneralLayout,
            children: [
                {path: 'order/:orderId', name: 'order', component: Order},
                {
                    path: 'my-office',
                    component: MyOffice,
                    children: [
                        {path: 'personal-data', name: 'my-office', component: PersonalData},
                        {path: 'orders', name: 'orders', component: Orders},
                        {path: 'favorites', name: 'favorites', component: Favorites}
                    ],
                    meta: {
                        title: 'Мой кабинет',
                        requiresAuth: true
                    }
                },
                {path: 'page/:id', name: 'page', component: Page},
                {path: 'direct', name: 'direct', component: Direct, meta: {title: 'Оплата по номеру договору'}}
            ]
        },
        {
            path: '/s/:storeId',
            component: ShopLayout,
            children: [
                {path: '', name: 'store', component: Services},
                {path: 'services/:serviceId', name: 'services', component: Services},
                {path: 'service/:serviceId', name: 'service', component: Service},
            ]
        },
        {path: '/login', name: 'login', component: Login},
        {path: '/logout', name: 'logout', component: LogOut},
        {path: '/callback/:provider', name: 'callback', component: Callback},
        {path: '/maintenance', name: 'maintenance', component: Maintenance},
        {path: '/error', name: 'error', component: Error},

        {path: '/admin/login', name: 'admin-login', component: AdminLogin},
        {
            path: '/admin',
            component: AdminDashboard,
            children: [
                {path: '', name: 'dashboard-tile', component: DashboardTile},
                {path: 'certificate', name: 'dashboard-certificate', component: DashboardCertificate},
                {path: 'errors', name: 'dashboard-errors', component: DashboardErrors},
                {path: 'error/:errorId', name: 'dashboard-error', component: DashboardError}
            ],
            meta: {requiresAdmin: true}
        },
    ]
})

router.beforeEach(async (to, from, next) => {
        if (store.state.userInfo === undefined) {
            try {
                let data = await RemoteMethods.V1.User.GetInfo()
                store.commit('setUserInfo', data)
            } catch (e) {
                if (e instanceof RemoteErrors.UserNotAuthenticated) {
                    store.commit('setUserInfo', null)
                } else
                    throw e;
            }
        }

        if (to.matched.some(record => record.meta.requiresAuth) && !store.getters.isAuthenticated) {
            next({name: 'login', query: {redirect: to.fullPath}});
            return;
        }

        if (to.matched.some(record => record.meta.requiresAdmin)) {
            if (store.getters.isAdmin === undefined) {
                store.commit('setAdmin', await RemoteMethods.Admin.IsLoggedIn());
            }

            if (!store.getters.isAdmin) {
                next({name: 'admin-login', query: {redirect: to.fullPath}});
                return
            }
        }

        next();
    }
)

export default router;