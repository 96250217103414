<template>
  <div>
    Выход из системы...
  </div>
</template>

<script>
export default {
  name: "logout",
  watch: {
    $route: 'logout'
  },
  methods: {
    async logout() {
      try {
        await this.$remoteCalls.V1.User.LogOut();
        window.location.href = '/';
      } catch (e) {
        this.$handleError(e)
      }
    }
  },
  created() {
    this.logout();
  }
}
</script>

<style scoped>

</style>