<template>
  <div>
    <h1 class="office__section-header">Заказы</h1>
    <div class="orders">
      <div v-for="order of orders" :key="order['ГУИД']">
        <img :src="order['Картинка'] | imagePath">
        <div class="description">
          <div class="title_amount">
            <div class="title">{{ order['Наименование'] }}</div>
            <div class="amount">{{ order['Сумма'] | currency }}</div>
          </div>
          <div class="enumeration_status">
            <div class="enumeration">
              <div>Заказ № {{ order["Номер"] }}</div>
              <div>Время {{ order["Дата"] | dateTime }}</div>
              <div>Состояние: {{ order['Статус'] | statusDescription }}</div>
            </div>
            <div></div>
          </div>
          <div class="operations">
            <a v-if="order['Чек']" :href="order['Чек']['Ссылка']" class="link" target="_blank">Кассовый чек</a>
            <router-link :to="{name: 'order', params: { orderId: order['ГУИД'] }}" class="btn">Подробнее</router-link>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import LinksComponent from '@/components/my-office/links'

export default {
  data() {
    return {
      orders: []
    }
  },
  created() {
    this.init()
  },
  watch: {
    $route: 'init'
  },
  computed: {
    hasCheck() {
      return
    }
  },
  methods: {
    async init() {
      try {
        this.orders = await this.$remoteCalls.V1.Order.GetAll()
      } catch (e) {
        this.$handleError(e);
      }
    }
  },
  filters: {
    statusDescription(status) {
      const map = new Map(
          [
            ['Создан', 'Необходимо заполнить обязательные поля заказа'],
            ['Заполнен', 'Ожидает оплаты']
          ]);

      return map.get(status) || status;
    }
  },
  components: {
    'links-component': LinksComponent
  }
}
</script>

<style scoped lang="scss">

.orders {
  display: flex;
  flex-direction: column;
  margin: 48px 0;

  > * {
    text-decoration: none;
    color: black;
    display: flex;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #DADADA;
    margin: 12px 0;

    img {
      display: block;
      margin: 22px;
      width: 156px;
      height: 156px;
    }
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  flex-grow: 1;
  margin: 22px;
}

.title_amount {
  display: flex;
  justify-content: space-between;
}

.title {
  font-weight: bold;
  font-size: 1.5rem;
}

.amount {
  font-weight: bold;
  font-size: 1.5rem;
  color: #8D0AF4;
  margin-left: 24px;
}

.operations {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.enumeration_status {
  display: flex;
}

.enumeration {
  font-family: 'Roboto', sans-serif;
  color: #BEBEBE;

  > div {
    margin-top: 8px;
  }
}

.status {

}

</style>