<template>
  <div class="dashboard">
    <div class="menu">
      <router-link :to="{name: 'dashboard-tile'}" class="menu-title">Панель администратора</router-link>
      <ul class="menu-list">
        <li>
          <router-link :to="{name: 'dashboard-certificate'}">Сертификат</router-link>
        </li>
        <li>
          <router-link :to="{name: 'dashboard-errors'}">Ошибки приложения</router-link>
        </li>
      </ul>
    </div>
    <div class="content">
      <router-view/>
    </div>
  </div>
</template>

<script>
export default {
  name: "dashboard"
}
</script>

<style scoped lang="scss">
.dashboard {
  display: flex;
  min-height: 100%;
}

.menu {
  flex: 0 0 250px;
  background-color: #313a46;
  color: white;
}

.menu-title {
  text-transform: uppercase;
  font-weight: bold;
  text-align: center;
  padding: 8px;
  line-height: 1.5;
  text-decoration: none;
  color: white;
  display: block;
}

.menu-list {
  list-style-type: none;

  a {
    color: rgb(131, 145, 162);
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: rgb(188, 206, 228)
    }

    &.router-link-active {
      color: white;
    }
  }

  li:not(:last-child) {
    margin-bottom: 8px;
  }
}

.content {
  flex: 1 1 auto;
}
</style>