import RemoteMethods from "@/remote/methods"
import RemoteErrors from "@/remote/errors";

const install = function (Vue) {
    Vue.prototype.$remoteErrors = RemoteErrors
    Vue.prototype.$remoteCalls = RemoteMethods
}

export default {
    install
}