import router from '@/router'
import store from '@/store'

import RemoteErrors from "@/remote/errors";

export default function (e) {
    if (e instanceof RemoteErrors.Maintenance) {
        if (router.currentRoute.name !== 'maintenance')
            router.push({name: 'maintenance'})
    } else {
        store.commit('setLastError', {
            name: e.name,
            message: e.message,
            file_name: e.fileName,
            line_number: e.lineNumber,
            column_number: e.columnNumber,
            stack: e.stack,
            location: window.location.toString()
        });

        router.push({name: 'error'})
    }
}