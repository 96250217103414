<template>
  <div>
    <div class="header-background"></div>
    <div class="header-container">
      <site-header-component></site-header-component>
    </div>
    <div class="common-content">
      <div class="container">
        <router-view/>
      </div>
      <footer-component></footer-component>
    </div>
  </div>
</template>

<script>
export default {
  name: "default-layout"
}
</script>

<style scoped lang="scss">

.header-background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 250px;
  background: url("@/assets/images/header-left-background.svg") no-repeat #F4F6F8;
}

.site-header {
  box-shadow: 0 1px 8px rgba(0, 0, 0, 0.1);
}

.common-content {
  position: relative;
  top: 300px;
  display: flex;
  flex-direction: column;
}
</style>