var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "title" }, [_vm._v(_vm._s(_vm.page["Заголовок"]))]),
    _vm._v(" "),
    _c("div", {
      domProps: {
        innerHTML: _vm._s(
          _vm.$options.filters.markdown(_vm.page["Текст"] || "")
        ),
      },
    }),
    _vm._v(" "),
    _c("br"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }