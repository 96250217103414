<template>
  <div>
    <h1>Оплата по номеру договора</h1>

    <div class="form-of-payment">
      <div>
        <p>Выполните вход на сайт. Это позволит сохранить информацию о платеже и электронном чеке в вашем
          профиле</p>
        <div class="search">
          <input v-model="contractNumber" type="text" placeholder="Номер договора" class="searching">
          <button @click="search" class="btn">
            <span v-show="searching"><i class="fas fa-spinner fa-spin"></i> Поиск...</span>
            <span v-show="!searching">Найти</span>
          </button>
        </div>
        <span v-show="contractInfo['Найден'] === undefined"><small><strong>Заполните поле договора.</strong> В поле можно указать номер договора, номер ордера поселения в
          общежитие, номер договора детского
          садика и прочее</small></span>
      </div>
      <span v-show="contractInfo['Найден']"><strong>Договор {{
          contractInfo['contractNumber']
        }} найден:</strong> {{ contractInfo['Описание'] }}</span>
      <span v-show="contractInfo['Найден'] === false"><strong>Договор {{
          contractInfo['contractNumber']
        }} не найден</strong></span>
      <div>
        <input v-model="payer" type="text" placeholder="Полное имя плательщика">
        <small v-show="!payerIsValid" class="error">Укажите полное имя плательщика</small>
      </div>
      <div>
        <input v-model="phone" type="text" placeholder="Контактный телефон">
      </div>
      <div>
        <input v-model="email" type="text" placeholder="Email">
        <small v-show="!emailIsValid" class="error">Укажите корректный электронный адрес. На этот адрес будет отправлен
          электронный чек</small>
      </div>
      <div>
        <input v-model="amount" type="text" placeholder="Сумма платежа">
        <small v-show="!amountIsValid" class="error">Укажите корректную сумму платежа</small>
      </div>
      <small>Безопасность платежей обеспечивается с помощью Банка-эквайера (Газпромбанк (Акционерное Общество)),
        функционирующего на основе современных протоколов и технологий, разработанных платежными системами МИР, Visa
        International и Mastercard Worldwide (3D-Secure: Verified by VISA, Mastercard SecureCode, MirAccept).
        Обработка полученных конфиденциальных данных Держателя карты производится в процессинговом центре Банка,
        сертифицированного по стандарту PCI DSS. Безопасность передаваемой информации обеспечивается с помощью
        современных протоколов обеспечения безопасности в сети Интернет.</small>
      <div>
        <button @click="proceed" :disabled="!orderFormValid" class="btn">
          <span v-if="orderProcessing"><i class="fas fa-spinner fa-spin"></i> Обработка заказа...</span>
          <span v-else>Перейти к оплате</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import {debounce} from 'lodash'

export default {
  name: "direct",
  data() {
    return {
      contractNumber: null,
      contractInfo: {},
      searching: false,
      amount: '',
      orderProcessing: false,
      payer: '',
      phone: '',
      email: ''
    }
  },
  created() {
    const userInfo = this.$store.state.userInfo;
    if (userInfo) {
      this.payer = [userInfo['Фамилия'], userInfo['Имя'], userInfo['Отчество']].join(' ')
      this.phone = userInfo['Телефон']
      this.email = userInfo['Email']
    }

    //const params = ['contract_number', 'client_name', 'client_phone', 'client_email', 'client_address', 'amount']
    if (this.$route.query.contract_number) {
      this.contractNumber = this.$route.query.contract_number;
      this.search()
    }
    if (this.$route.query.amount) {
      this.amount = this.$route.query.amount;
    }

    this.$watch('contractNumber', debounce(this.search, 1500))
  },
  computed: {
    payerIsValid() {
      return this.payer.trim().length > 3;
    },
    amountIsValid() {
      const amountValidator = /^\d+([.,]\d{2})?$/;
      return amountValidator.test(this.amount) && this.parseAmount(this.amount) > 0;
    },
    emailIsValid() {
      let emailValidator = new RegExp("([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\"\(\[\]!#-[^-~ \t]|(\\[\t -~]))+\")@([!#-'*+/-9=?A-Z^-~-]+(\.[!#-'*+/-9=?A-Z^-~-]+)*|\[[\t -Z^-~]*])");
      return emailValidator.test(this.email)
    },
    orderFormValid() {
      return this.contractInfo['Найден'] && this.payerIsValid && this.emailIsValid && this.amountIsValid;
    },
  },
  methods: {
    parseAmount() {
      return Number.parseFloat(this.amount.replace(',', '.'));
    },
    async search() {
      if (this.searching) return;

      this.searching = true;
      try {
        const contractNumber = this.contractNumber
        this.contractInfo = await this.$remoteCalls.V1.DirectPayments.FindByContractNumber({contract_number: this.contractNumber})
        this.contractInfo['contractNumber'] = contractNumber;
      } finally {
        this.searching = false;
      }
    },
    async proceed() {
      if (this.orderProcessing) return;

      this.orderProcessing = true;
      try {
        const order = {
          contract: this.contractInfo['Договор'], payer: this.payer,
          phone: this.phone, email: this.email, amount: this.parseAmount(this.amount)
        }
        const orderId = await this.$remoteCalls.V1.DirectPayments.CreateOrder(order)
        const url = await this.$remoteCalls.V1.PaymentGateway.Link({
          order_id: orderId
        })
        window.location.href = url
      } finally {
        this.orderProcessing = false;
      }
    }
  }
}
</script>

<style scoped lang="scss">
.form-of-payment {
  display: flex;
  flex-direction: column;
  gap: 32px;
  padding-bottom: 32px;
}

.search {
  display: flex;

  button {
    white-space: nowrap;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.error {
  color: red;
}

</style>