<template>
  <div v-if="userInfo" class="menu">
    <router-link :to="{ name: 'my-office' }" class="main-link">
      <div v-if="userInfo">{{ userInfo["Наименование"] }}</div>
      <img src="@/assets/images/user.svg" alt="">
    </router-link>
    <ul>
      <li class="item">
        <router-link :to="{ name: 'my-office' }" class="profile-link link">
          <img src="@/assets/images/my-office.svg" alt="">
          <div>Личный кабинет</div>
        </router-link>
      </li>
      <li class="item">
        <router-link :to="{ name: 'orders' }" class="profile-link link">
          <img src="@/assets/images/orders.svg" alt="">
          <div>Мои заказы</div>
        </router-link>
      </li>
      <li class="item">
        <router-link :to="{ name: 'favorites' }" class="profile-link link">
          <img src="@/assets/images/favorites.svg" alt="">
          <div>Избранное</div>
        </router-link>
      </li>
      <li>
        <div class="separator"></div>
      </li>
      <li class="item">
        <router-link :to="{ name: 'logout' }" class="link">
          Выйти
        </router-link>
      </li>
    </ul>
  </div>
  <router-link v-else :to="loginPath()" class="main-link">
    <div>Вход</div>
    <img src="@/assets/images/image-not-found.svg" alt="">
  </router-link>
</template>

<script>
export default {
  data() {
    return {}
  },
  computed: {
    userInfo() {
      return this.$store.state.userInfo
    }
  },
  methods: {
    loginPath() {
      return {
        name: 'login',
        query: {
          redirect: location.pathname
        }
      }
    }
  }
}
</script>

<style scoped lang="scss">

.main-link {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin: 0 -4px;

  font-family: "Montserrat", sans-serif;
  font-weight: 600;
  text-decoration: none;
  color: black;

  > * {
    margin: 0 4px;
  }
}

.main-link img {
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: block;
}

.link {
  display: block;
  font-family: "Montserrat", sans-serif;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: none;
  color: rgb(117, 117, 117);
  padding: 16px 20px;

}

.menu {
  position: relative;

  > ul {
    min-width: 200px;
    display: none;
    position: absolute;
    list-style: none;

    right: 0;

    padding: 0;
    margin: 0;

    border: 1px solid #DADADA;
    border-radius: 4px;

    background-color: white;

    flex-direction: column;

    z-index: 1000;

    > li {
      display: block;
    }
  }

  &:hover > ul {
    display: flex;
  }
}

.profile-link {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: 0 -4px;

  > * {
    margin: 0 4px;
  }
}

.item {
}

.separator {
  border-top: 1px solid rgb(230, 230, 230);
}


</style>