<template>
  <div class="center">
    <h4><i class="fas fa-spinner fa-spin"></i> Регистрация пользователя...</h4>
  </div>
</template>

<script>

import {Base64} from "js-base64";

export default {
  data() {
    return {}
  },
  computed: {
    params() {
      return this.$route.params;
    }
  },
  created() {
    this.registerUser();
  },
  methods: {
    async registerUser() {
      try {
        let provider = this.$route.params.provider;
        let code = this.$route.query.code;

        await this.$remoteCalls.V1.User.AuthenticateOrRegister({provider, code})
        await this.loadUserInfo()

        const state = JSON.parse(Base64.decode(this.$route.query.state))

        if (state.origin) {
          const accessToken = await this.$remoteCalls.V1.User.GetAccessToken();
          const pathname = '/login?accessToken=:accessToken&redirect=:redirect'
              .replace(':accessToken', accessToken)
              .replace(':redirect', state.redirect)
          window.location.replace(state.origin + pathname);
        } else if (state.redirect) {
          window.location.replace(state.redirect)
        } else {
          await this.$router.replace({name: 'root'})
        }
      } catch (e) {
        this.$handleError(e)
        throw e;
      }
    },
    async loadUserInfo() {
      try {
        let data = await this.$remoteCalls.V1.User.GetInfo()
        this.$store.commit('setUserInfo', data)
      } catch (e) {
        if (e instanceof this.$remoteErrors.UserNotAuthenticated) {
          this.$store.commit('setUserInfo', null)
        } else
          this.$handleError(e)
      }
    }
  }
}
</script>

<style scoped lang="scss">
.center {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  align-items: center;
  justify-content: center;
}
</style>