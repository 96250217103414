var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "office__section-header" }, [_vm._v("Заказы")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "orders" },
      _vm._l(_vm.orders, function (order) {
        return _c("div", { key: order["ГУИД"] }, [
          _c("img", { attrs: { src: _vm._f("imagePath")(order["Картинка"]) } }),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _c("div", { staticClass: "title_amount" }, [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(order["Наименование"])),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "amount" }, [
                _vm._v(_vm._s(_vm._f("currency")(order["Сумма"]))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "enumeration_status" }, [
              _c("div", { staticClass: "enumeration" }, [
                _c("div", [_vm._v("Заказ № " + _vm._s(order["Номер"]))]),
                _vm._v(" "),
                _c("div", [
                  _vm._v("Время " + _vm._s(_vm._f("dateTime")(order["Дата"]))),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "Состояние: " +
                      _vm._s(_vm._f("statusDescription")(order["Статус"]))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div"),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "operations" },
              [
                order["Чек"]
                  ? _c(
                      "a",
                      {
                        staticClass: "link",
                        attrs: {
                          href: order["Чек"]["Ссылка"],
                          target: "_blank",
                        },
                      },
                      [_vm._v("Кассовый чек")]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "router-link",
                  {
                    staticClass: "btn",
                    attrs: {
                      to: { name: "order", params: { orderId: order["ГУИД"] } },
                    },
                  },
                  [_vm._v("Подробнее")]
                ),
              ],
              1
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }