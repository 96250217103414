var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "cont" },
    [
      _c("h2", [_vm._v("Сертификат")]),
      _vm._v(" "),
      _vm.info
        ? [
            _c("div", { staticClass: "certs" }, [
              _c("div", { staticClass: "certificate" }, [
                _vm._m(0),
                _vm._v(" "),
                _c("div", [
                  _vm._v("Идентификатор " + _vm._s(_vm.info.live.serial)),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "Действителен с " +
                      _vm._s(_vm._f("dateTime")(_vm.info.live.not_before))
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "Действителен по " +
                      _vm._s(_vm._f("dateTime")(_vm.info.live.not_after))
                  ),
                ]),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "certificate" }, [
                _vm._m(1),
                _vm._v(" "),
                _c("div", [
                  _vm._v("Идентификатор " + _vm._s(_vm.info.approved.serial)),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "Действителен с " +
                      _vm._s(_vm._f("dateTime")(_vm.info.approved.not_before))
                  ),
                ]),
                _vm._v(" "),
                _c("div", [
                  _vm._v(
                    "Действителен по " +
                      _vm._s(_vm._f("dateTime")(_vm.info.approved.not_after))
                  ),
                ]),
              ]),
            ]),
            _vm._v(" "),
            _c(
              "button",
              {
                staticClass: "btn",
                attrs: {
                  disabled: _vm.info.live.serial === _vm.info.approved.serial,
                },
                on: { click: _vm.commit },
              },
              [
                _c("i", {
                  directives: [
                    {
                      name: "show",
                      rawName: "v-show",
                      value: _vm.committing,
                      expression: "committing",
                    },
                  ],
                  staticClass: "fas fa-spinner fa-spin",
                }),
                _vm._v("\n      Обновить сертификат сервера\n    "),
              ]
            ),
          ]
        : _c("div", [_vm._v("\n    Получение данных...\n  ")]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("Последний выданный сертификат")])])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("strong", [_vm._v("Установленный сертификат")])])
  },
]
render._withStripped = true

export { render, staticRenderFns }