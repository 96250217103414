var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "background" }),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "header-container" },
      [_c("site-header-component", { attrs: { "hide-right-menu": "" } })],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "wrapper" }, [
      _c("div", { staticClass: "form" }, [
        _vm.tab === "links"
          ? _c("div", { staticClass: "links" }, [
              _c("h1", [_vm._v("Вход в магазин")]),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.vkontakte } }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/vk.svg") },
                }),
                _vm._v(" "),
                _c("div", [_vm._v("Войти через Vkontakte")]),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.google } }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/google.svg") },
                }),
                _vm._v(" "),
                _c("div", [_vm._v("Войти через Google")]),
              ]),
              _vm._v(" "),
              _c("a", { attrs: { href: _vm.urfu } }, [
                _c("img", {
                  attrs: { src: require("@/assets/images/urfu.png") },
                }),
                _vm._v(" "),
                _c("div", [_vm._v("Войти через ЛК УрФУ")]),
              ]),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.enterEmail()
                    },
                  },
                },
                [
                  _c("img", {
                    attrs: { src: require("@/assets/images/email.svg") },
                  }),
                  _vm._v(" "),
                  _c("div", [_vm._v("Войти через email")]),
                ]
              ),
            ])
          : _vm.tab === "email"
          ? _c("div", { staticClass: "email-form" }, [
              _c("h1", [_vm._v("Вход через email")]),
              _vm._v(" "),
              _c("p", [_vm._v("Укажите свой email адрес для входа на сайт")]),
              _vm._v(" "),
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.email,
                    expression: "email",
                  },
                ],
                ref: "emailInput",
                domProps: { value: _vm.email },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    ) {
                      return null
                    }
                    return _vm.sendLetter()
                  },
                  input: function ($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.email = $event.target.value
                  },
                },
              }),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "btn",
                  on: {
                    click: function ($event) {
                      return _vm.sendLetter()
                    },
                  },
                },
                [_vm._v("Продолжить")]
              ),
              _vm._v(" "),
              _c(
                "a",
                {
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      _vm.tab = "links"
                    },
                  },
                },
                [_vm._v("Вернуться ко всем вариантам входа")]
              ),
            ])
          : _vm.tab === "emailSent"
          ? _c(
              "div",
              { staticClass: "email-sent" },
              [
                _c("h1", [_vm._v("Проверьте свою почту")]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Для завершения входа перейдите по ссылке в отправленном для вас письме на " +
                      _vm._s(_vm.email) +
                      " "
                  ),
                ]),
                _vm._v(" "),
                _c(
                  "router-link",
                  { staticClass: "btn", attrs: { to: { name: "root" } } },
                  [_vm._v("OK")]
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }