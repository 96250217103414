var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.footerTree
    ? _c("footer", [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "main" }, [
            _c(
              "div",
              { staticClass: "general-info" },
              _vm._l(_vm.other, function (group) {
                return _c(
                  "div",
                  [
                    _c("h4", [_vm._v(_vm._s(group["Наименование"]))]),
                    _vm._v(" "),
                    _vm._l(group["Элементы"], function (item) {
                      return _c(
                        "div",
                        [
                          _c(
                            "router-link",
                            {
                              attrs: {
                                to: {
                                  name: "page",
                                  params: {
                                    id: item["shortname"] || item["ГУИД"],
                                  },
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(item["Наименование"]) +
                                  "\n            "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                )
              }),
              0
            ),
          ]),
          _vm._v(" "),
          _c("hr"),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "subsection" },
            [
              _vm._m(0),
              _vm._v(" "),
              _vm._l(_vm.terms, function (item) {
                return _c(
                  "div",
                  [
                    _c(
                      "router-link",
                      {
                        attrs: {
                          to: {
                            name: "page",
                            params: { id: item["shortname"] || item["ГУИД"] },
                          },
                        },
                      },
                      [_vm._v(_vm._s(item["Наименование"]) + "\n        ")]
                    ),
                  ],
                  1
                )
              }),
            ],
            2
          ),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", {
        attrs: {
          height: "50",
          src: require("@/assets/images/logo-letters.svg"),
          alt: "",
        },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }