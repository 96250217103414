var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "office__section-header" }, [
      _vm._v("Личные данные"),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "data" }, [
      _c("div", { staticClass: "grid" }, [
        _c("div", [_vm._v("Фамилия")]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userInfo["Фамилия"],
                expression: "userInfo['Фамилия']",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.userInfo["Фамилия"] },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.userInfo, "Фамилия", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("Имя")]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userInfo["Имя"],
                expression: "userInfo['Имя']",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.userInfo["Имя"] },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.userInfo, "Имя", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("Отчество")]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userInfo["Отчество"],
                expression: "userInfo['Отчество']",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.userInfo["Отчество"] },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.userInfo, "Отчество", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("Email")]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userInfo["Email"],
                expression: "userInfo['Email']",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.userInfo["Email"] },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.userInfo, "Email", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div", [_vm._v("Телефон")]),
        _vm._v(" "),
        _c("div", [
          _c("input", {
            directives: [
              {
                name: "model",
                rawName: "v-model",
                value: _vm.userInfo["Телефон"],
                expression: "userInfo['Телефон']",
              },
            ],
            attrs: { type: "text" },
            domProps: { value: _vm.userInfo["Телефон"] },
            on: {
              input: function ($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.$set(_vm.userInfo, "Телефон", $event.target.value)
              },
            },
          }),
        ]),
        _vm._v(" "),
        _c("div"),
        _vm._v(" "),
        _c("div", { staticClass: "last" }, [
          _c(
            "button",
            {
              staticClass: "btn",
              attrs: { disabled: _vm.saving },
              on: { click: _vm.saveUserInfo },
            },
            [
              _c("i", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.saving,
                    expression: "saving",
                  },
                ],
                staticClass: "fas fa-cog fa-spin",
              }),
              _vm._v(" Сохранить изменения"),
            ]
          ),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }