var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("h1", { staticClass: "office__section-header" }, [_vm._v("Избранное")]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "favorites" },
      _vm._l(_vm.favorites, function (it) {
        return _c("div", { class: { unpublished: !it["Опубликовано"] } }, [
          _c("img", { attrs: { src: _vm._f("imagePath")(it["Картинка"]) } }),
          _vm._v(" "),
          _c("div", { staticClass: "description" }, [
            _c("div", { staticClass: "title" }, [
              _vm._v(_vm._s(it["Наименование"])),
            ]),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "operations" },
              [
                _c(
                  "button",
                  {
                    staticClass: "btn favorite",
                    attrs: { disabled: it.updating },
                    on: {
                      click: function ($event) {
                        return _vm.toggleFavorite(it)
                      },
                    },
                  },
                  [
                    _c("i", {
                      staticClass: "fa-xl fa-heart",
                      class: [it["Включен"] ? "fa-solid" : "fa-regular"],
                    }),
                  ]
                ),
                _vm._v(" "),
                it["Опубликовано"]
                  ? _c(
                      "router-link",
                      {
                        staticClass: "btn",
                        attrs: {
                          to: {
                            name: "service",
                            params: {
                              storeId: it["Магазин"],
                              serviceId: it["Услуга"],
                            },
                          },
                        },
                      },
                      [_vm._v("\n            Перейти\n          ")]
                    )
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ])
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }