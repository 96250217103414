var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "header-container" }, [
    _c("div", { staticClass: "header-wrapper" }, [
      _c("div", { staticClass: "site-header" }, [
        _c(
          "div",
          { staticClass: "container" },
          [
            _c("router-link", { attrs: { to: { name: "root" } } }, [
              _c("img", {
                staticClass: "logo",
                attrs: { src: require("@/assets/images/logo-market.svg") },
              }),
            ]),
            _vm._v(" "),
            _vm.hideRightMenu !== true
              ? _c(
                  "div",
                  { staticClass: "right-menu" },
                  [_c("user-component")],
                  1
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }