var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "my-office__container" },
    [
      _c("div", { staticClass: "menu" }, [
        _vm._m(0),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "submenu" },
          [
            _c(
              "router-link",
              { staticClass: "title", attrs: { to: { name: "my-office" } } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/personal-data.svg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("Личные данные")]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "submenu" },
          [
            _c(
              "router-link",
              { staticClass: "title", attrs: { to: { name: "orders" } } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/orders.svg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("Мои заказы")]),
              ]
            ),
          ],
          1
        ),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "submenu" },
          [
            _c(
              "router-link",
              { staticClass: "title", attrs: { to: { name: "favorites" } } },
              [
                _c("img", {
                  attrs: {
                    src: require("@/assets/images/favorites.svg"),
                    alt: "",
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("Избранное")]),
              ]
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("router-view"),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "submenu" }, [
      _c("div", { staticClass: "title" }, [
        _c("img", {
          attrs: { src: require("@/assets/images/my-office.svg"), alt: "" },
        }),
        _vm._v(" "),
        _c("span", [_vm._v("Личный кабинет")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }