var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "dashboard" }, [
    _c(
      "div",
      { staticClass: "menu" },
      [
        _c(
          "router-link",
          {
            staticClass: "menu-title",
            attrs: { to: { name: "dashboard-tile" } },
          },
          [_vm._v("Панель администратора")]
        ),
        _vm._v(" "),
        _c("ul", { staticClass: "menu-list" }, [
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "dashboard-certificate" } } },
                [_vm._v("Сертификат")]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "li",
            [
              _c(
                "router-link",
                { attrs: { to: { name: "dashboard-errors" } } },
                [_vm._v("Ошибки приложения")]
              ),
            ],
            1
          ),
        ]),
      ],
      1
    ),
    _vm._v(" "),
    _c("div", { staticClass: "content" }, [_c("router-view")], 1),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }