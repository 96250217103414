var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "panel" }, [
    _c("h2", [_vm._v("Ошибка #" + _vm._s(_vm.errorId))]),
    _vm._v(" "),
    _c("div", { staticClass: "error" }, [
      _c("div", { staticClass: "title" }, [_vm._v("Дата")]),
      _vm._v(" "),
      _c("div", { staticClass: "value" }, [
        _vm._v(_vm._s(_vm._f("dateTime")(_vm.error.created_at))),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("Имя")]),
      _vm._v(" "),
      _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.error.name))]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("Описание")]),
      _vm._v(" "),
      _c("div", { staticClass: "value" }, [
        _vm._v(_vm._s(_vm.error.message ? _vm.error.message : " --- ")),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [
        _vm._v("Сообщение от пользователя"),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "value" }, [
        _vm._v(
          _vm._s(_vm.error.user_message ? _vm.error.user_message : " --- ")
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("Страница")]),
      _vm._v(" "),
      _c("div", { staticClass: "value" }, [_vm._v(_vm._s(_vm.error.location))]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("Файл")]),
      _vm._v(" "),
      _c("div", { staticClass: "value" }, [
        _vm._v(
          _vm._s(_vm.error.file_name) +
            " " +
            _vm._s(_vm.error.line_number) +
            ":" +
            _vm._s(_vm.error.column_number)
        ),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "title" }, [_vm._v("Стек вызовов функций")]),
      _vm._v(" "),
      _c("div", [_c("pre", [_vm._v(_vm._s(_vm.error.stack))])]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }