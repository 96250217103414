import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        stores: {},
        userInfo: undefined,
        lastError: null,
        footerTree: null,
        isAdmin: undefined
    },
    getters: {
        isAuthenticated: state => {
            return !!state.userInfo;
        },
        isAdmin: state => {
            return state.isAdmin;
        }
    },
    mutations: {
        setStore(state, {storeId, data}) {
            Vue.set(state.stores, storeId, data);
        },
        setUserInfo(state, payload) {
            state.userInfo = payload
        },
        setLastError(state, error) {
            state.lastError = error;
        },
        setFooterTree(state, footerTree) {
            state.footerTree = footerTree;
        },
        setAdmin(state, isAdmin) {
            state.isAdmin = isAdmin;
        }
    }
})