<template>
  <div>
    <div class="shop-top-content">
      <div class="shop-header">{{ data['НаименованиеРаздела'] || "Раздел" }}</div>
      <div class="shop-groups-wrapper">
        <div class="shop-groups">
          <div v-for="item in data['Группы']" :key="item['ГУИД']" class="shop-group">
            <router-link
                :to="{ name: 'services', params: { storeId: storeId, serviceId: item['ГУИД'] } }">
              <img :src="item['Миниатюра'] | imagePath"/>
              <div class="shop-group__text">{{ item['Наименование'] }}</div>
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <breadcrumbs-component :chain="breadcrumbs"></breadcrumbs-component>
    <div class="shop-services-header">
      <h1 class="header">Услуги</h1>
      <div class="shop-filters">
        <div v-for="filter in data['Фильтры']">
          <form-select v-model="selectedFilters[filter['ГУИД']]" @input="fetchData"
                       :options="filter['Значения'] | adaptList">{{
              filter["Наименование"]
            }}
          </form-select>
        </div>
      </div>
      <div v-if="data['Элементы'].length > 0 || data['Фильтры'].length > 0" class="shop-clear-filter-link">
        <a @click.prevent="clearFilters()" href="#">Очистить фильтры</a>
      </div>
    </div>
    <div v-if="data['Элементы'].length > 0" class="tiles">
      <div v-for="item in data['Элементы']" :key="item['ГУИД']" class="tile">
        <router-link :to="{ name: 'service', params: { storeId: storeId, serviceId: item['ГУИД'] } }">
          <img :src="item['Миниатюра'] | imagePath" alt="" class="tile__image">
        </router-link>
        <div class="tile__content">
          <router-link :to="{ name: 'service', params: { storeId: storeId, serviceId: item['ГУИД'] } }"
                       class="tile__header">{{ item['Наименование'] }}
          </router-link>
          <div class="tile__amount">
            {{ item["Стоимость"] | amount }}
          </div>
          <div class="tile__description">
            {{ item["КраткоеОписание"] }}
          </div>
          <div class="tile__actions">
            <router-link :to="{ name: 'service', params: { storeId: storeId, serviceId: item['ГУИД'] } }"
                         class="btn">
              подробнее
            </router-link>
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <h4>Нет данных</h4>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import FormSelect from '@/components/common/form-select'

export default {
  data() {
    return {
      data: {
        "Группы": [],
        "Элементы": [],
        "Фильтры": []
      },
      selectedFilters: {}
    };
  },
  computed: {
    breadcrumbs() {
      const path = (this.data['Путь'] || [])
      return path.map(it => {
        return {name: it['Наименование'], to: {name: 'services', params: {serviceId: it['ГУИД']}}}
      });
    },
    serviceId() {
      return this.$route.params.serviceId;
    },
    storeId() {
      return this.$route.params.storeId;
    }
  },
  watch: {
    $route: 'init'
  },
  created() {
    this.init();
  },
  methods: {
    init() {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.fetchData();
    },
    async fetchData() {
      try {
        let filters = Object.entries(this.selectedFilters)
            .filter(([_, value]) => value !== null).map(([name, value]) => [name, [value]]);
        filters = Object.fromEntries(filters);
        if (Object.keys(filters).length === 0) filters = null;

        this.data = await this.$remoteCalls.V1.Service.GetServices({
          store_id: this.storeId,
          service_id: this.serviceId || null,
          filters: filters
        })

        this.selectedFilters = Object.assign(
            {},
            Object.fromEntries(this.data["Фильтры"].map(i => [i["ГУИД"], null])),
            this.selectedFilters)
      } catch (e) {
        this.$handleError(e)
      }
    },
    clearFilters() {
      this.selectedFilters = {};
      this.fetchData()
    },

  },
  filters: {
    amount(value) {
      value = Array.isArray(value) ? value : [value]
      return value.map(Vue.filter('currency')).join(' - ')
    },
    adaptList: function (list) {
      return list.map((it) => {
        return {
          name: it['Имя'],
          value: it['Код'],
          disabled: false
        }
      })
    }
  },
  components: {
    'form-select': FormSelect
  }
}

</script>

<style scoped lang="scss">

.tiles {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px 0;
}

.tile {
  display: flex;
  align-items: stretch;
  border: 1px solid #DADADA;
}

.tile__image {
  display: block;
  width: calc(0.14 * 100vw + 36.36px);
  height: calc(0.14 * 100vw + 36.36px);
  object-fit: cover;
}

.tile__header {
  text-decoration: none;
  color: black;
  font-size: 1.2rem;
  font-weight: 700;
  margin: 0;
  height: 100%;
  overflow-y: hidden;
}

.tile__content {
  flex-grow: 1;

  display: grid;
  grid-template-columns: auto auto;
  justify-content: space-between;

  padding: 24px;
}

.tile__amount {
  justify-self: right;

  font-weight: bold;
  font-size: 1.3rem;
  color: #8D0AF4;
}

.tile__description {
  font-family: "Roboto", sans-serif;
  color: #676767;
}

.tile__actions {
  justify-self: right;
  align-self: end;
}

@media screen and (max-width: $mobile-width) {
  .tile__image {
    width: 80px;
    height: 80px;
  }
}

@media screen and (max-width: $tablet-width) {
  .tile__image {
    margin: 9px 0 9px 9px;
  }

  .tile__content {
    grid-template-columns: auto;
    gap: 9px;
  }

  .tile__amount {
    justify-self: left;
  }

  .tile__actions {
    justify-self: left;
  }
}


@media screen and (min-width: $container-width) {
  .tile__image {
    width: 200px;
    height: 200px;
  }
}
</style>