export class ActionError extends Error {
    constructor(code, message) {
        super(message);
        this.code = code
        this.name = 'ActionError'
    }
}

export class InternalError extends ActionError {
    constructor(message) {
        super(1, message);
        this.name = 'InternalError';
    }
}

export class MethodNotImplementedError extends ActionError {
    constructor(message) {
        super(2, message);
        this.name = 'MethodNotImplementedError';
    }
}

export class BadParametersError extends ActionError {
    constructor(message) {
        super(3, message);
        this.name = 'BadParametersError';
    }
}

export class UserNotAuthenticated extends ActionError {
    constructor(message) {
        super(4, message);
        this.name = 'UserNotAuthenticated';
    }
}

export class UserNotFoundError extends ActionError {
    constructor(message) {
        super(5, message);
        this.name = 'UserNotFoundError';
    }
}

export class Maintenance extends ActionError {
    constructor(message) {
        super(6, message);
        this.name = 'Maintenance';
    }
}

export class AdminNotAuthenticated extends ActionError {
    constructor(message) {
        super(7, message);
        this.name = 'AdminNotAuthenticated';
    }
}

export default {
    ActionError,
    AdminNotAuthenticated,
    BadParametersError,
    InternalError,
    Maintenance,
    MethodNotImplementedError,
    UserNotAuthenticated,
    UserNotFoundError,
    1: InternalError,
    2: MethodNotImplementedError,
    3: BadParametersError,
    4: UserNotAuthenticated,
    5: UserNotFoundError,
    6: Maintenance,
    7: AdminNotAuthenticated
}
