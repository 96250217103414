<template>
  <div class="panel">
    <h2>Ошибки</h2>

    <table>
      <thead>
      <tr>
        <th>Идентификатор</th>
        <th nowrap>Дата создания</th>
        <th>Имя</th>
        <th>Описание</th>
      </tr>
      </thead>
      <tbody>
      <template v-for="it in errors">
        <tr @click="showError(it.id)" style="cursor: pointer">
          <td style="text-align: center" nowrap>{{ it.id }}</td>
          <td style="text-align: center" nowrap>{{ it.created_at | dateTime }}</td>
          <td style="text-align: center" nowrap>{{ it.name }}</td>
          <td v-if="it.message.length > 150">{{ truncate(it.message, 150, true) }} &hellip;</td>
          <td v-else>{{ it.message }}</td>
        </tr>
        <tr v-if="it.user_message instanceof String && it.user_message.trim().length > 0">
          <td colspan="4"><strong>Сообщение пользователя:</strong> {{ it.user_message }}</td>
        </tr>
      </template>
      </tbody>
    </table>
  </div>
</template>

<script>

import {delay} from 'lodash'

export default {
  name: "errors",
  data() {
    return {
      errors: []
    }
  },
  created() {
    this.load()
  },
  methods: {
    showError(id) {
      this.$router.push({name: 'dashboard-error', params: {errorId: id}})
    },
    async load() {
      this.errors = await this.$remoteCalls.V1.Error.GetList();
      delay(this.load, 10000);
    },
    truncate(str, n, useWordBoundary) {
      if (str.length <= n) {
        return str;
      }
      const subString = str.substring(0, n - 1); // the original check
      return (useWordBoundary
          ? subString.substring(0, subString.lastIndexOf(" "))
          : subString);
    }
  }
}
</script>

<style scoped>

table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
}

table, td, th {
  border: 1px solid #dee2e6;
}

table tbody {
  font-family: "Roboto", sans-serif;
  text-align: justify;
}

tr th {
  text-align: center;
}

th, td {
  padding: 12px
}

.panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}
</style>