var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c(
      "div",
      {
        ref: "recommendation",
        staticClass: "shopfront-recommendation-wrapper",
      },
      [
        _c("div", { staticClass: "container" }, [
          _c("div", { staticClass: "shopfront-recommendation-header" }, [
            _vm._v("Рекомендуем"),
          ]),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "shopfront-recommendation-tiles",
              staticStyle: { "overflow-x": "auto", "scrollbar-width": "none" },
            },
            _vm._l(_vm.recommendations, function (item) {
              return _c(
                "a",
                {
                  staticClass: "shopfront-recommendation-tile",
                  attrs: {
                    target: "_blank",
                    href: item["Ссылка"],
                    title: item["Наименование"],
                  },
                },
                [
                  _c("h3", [_vm._v(_vm._s(item["Наименование"]))]),
                  _vm._v(" "),
                  _c("img", {
                    attrs: { src: _vm._f("imagePath")(item["Изображение"]) },
                  }),
                ]
              )
            }),
            0
          ),
        ]),
      ]
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "container" },
      [
        _c("breadcrumbs-component", { attrs: { chain: _vm.breadcrumbs } }),
        _vm._v(" "),
        _c("div", { staticClass: "shopfront-services-header" }, [
          _vm._v("Каталог услуг"),
        ]),
        _vm._v(" "),
        _vm.currentSection
          ? _c(
              "div",
              { staticClass: "shopfront-services-tiles" },
              _vm._l(_vm.currentSection["Элементы"], function (item) {
                return _c(
                  "div",
                  { staticClass: "shopfront-services-tile" },
                  [
                    item["Вид"] === "Раздел"
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "section",
                                params: { id: item["ГУИД"] },
                              },
                              title: item["Имя"],
                            },
                          },
                          [
                            _c("h3", [_vm._v(_vm._s(item["Имя"]))]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: _vm._f("imagePath")(item["Миниатюра"]),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item["Вид"] === "Сайт"
                      ? _c(
                          "a",
                          {
                            attrs: {
                              target: "_blank",
                              href: item["Url"],
                              title: item["Имя"],
                            },
                          },
                          [
                            _c("h3", [_vm._v(_vm._s(item["Имя"]))]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: _vm._f("imagePath")(item["Миниатюра"]),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    item["Вид"] === "Магазин"
                      ? _c(
                          "router-link",
                          {
                            attrs: {
                              to: {
                                name: "store",
                                params: {
                                  storeId: item["shortname"] || item["ГУИД"],
                                },
                              },
                              title: item["Имя"],
                            },
                          },
                          [
                            _c("h3", [_vm._v(_vm._s(item["Имя"]))]),
                            _vm._v(" "),
                            _c("img", {
                              attrs: {
                                src: _vm._f("imagePath")(item["Миниатюра"]),
                              },
                            }),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                )
              }),
              0
            )
          : _c("div", [_vm._v("Получение данных...")]),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }