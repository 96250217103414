import Vue from 'vue'

import VueUtils from '@/plugins/vue-utils'
Vue.use(VueUtils)

import RemoteCallPlugin from '@/plugins/remote-call-plugin'
Vue.use(RemoteCallPlugin)

import VuePageTitle from 'vue-page-title'
import router from '@/router'

Vue.use(VuePageTitle, { router })