<template>
  <div>
    <h1 class="title">{{ page['Заголовок'] }}</h1>
    <div v-html="$options.filters.markdown(page['Текст'] || '')"></div>
    <br>
  </div>
</template>

<script>
export default {
  data() {
    return {
      page: {}
    }
  },
  watch: {
    '$route': function () {
      this.loadData();
    }
  },
  created() {
    this.loadData();
  },
  methods: {
    async loadData() {
      this.page = await this.$remoteCalls.V1.Pages.GetPage({id: this.$route.params.id})
    }
  }
}
</script>

<style scoped>

</style>