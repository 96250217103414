<template>
  <div class="cont">
    <h2>Сертификат</h2>
    <template v-if="info">
      <div class="certs">
        <div class="certificate">
          <div><strong>Последний выданный сертификат</strong></div>
          <div>Идентификатор {{ info.live.serial }}</div>
          <div>Действителен с {{ info.live.not_before | dateTime }}</div>
          <div>Действителен по {{ info.live.not_after | dateTime }}</div>
        </div>
        <div class="certificate">
          <div><strong>Установленный сертификат</strong></div>
          <div>Идентификатор {{ info.approved.serial }}</div>
          <div>Действителен с {{ info.approved.not_before | dateTime }}</div>
          <div>Действителен по {{ info.approved.not_after | dateTime }}</div>
        </div>
      </div>
      <button @click="commit" :disabled="info.live.serial === info.approved.serial" class="btn"><i v-show="committing"
                                                                                                   class="fas fa-spinner fa-spin"></i>
        Обновить сертификат сервера
      </button>
    </template>
    <div v-else>
      Получение данных...
    </div>
  </div>
</template>

<script>
import RemoteMethods from '@/remote/methods'
import iziToast from "izitoast";

export default {
  name: "admin-certificate",
  data() {
    return {
      info: null,
      committing: false,
    }
  },
  async created() {
    this.info = await RemoteMethods.Admin.Certificate.GetState()
  },
  methods: {
    async commit() {
      this.committing = true;
      try {
        const response = await RemoteMethods.Admin.Certificate.Commit();
        if (response.success)
          iziToast.success({message: response.message})
        else
          iziToast.error({message: response.message})

        this.info = await RemoteMethods.Admin.Certificate.GetState();
      } finally {
        this.committing = false;
      }
    }
  }
}
</script>

<style scoped>
.cont {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
}

.certs {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
  flex-wrap: wrap;
}

.certificate {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 16px;
  border: 1px solid gray;
  border-radius: 6px;
}
</style>